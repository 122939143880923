import React,{useEffect} from "react";
import { BrowserRouter, BrowserRouter as Router,Route,Switch, useHistory } from "react-router-dom";
import ReactDOM from 'react-dom';


import { NavLink,Link } from "react-router-dom";
import $ from 'jquery';

import drivers from "../../../images/drivers.svg";
import financial from "../../../images/financial.svg";
import home from "../../../images/home.svg";
import load from "../../../images/load.svg";
import map from "../../../images/map.svg";
import setting from "../../../images/setting.svg";
import profile from "../../../images/profile.jpg";
import logo from "../../../images/logo.png";
import logoMini from "../../../images/logoMini.png";
import { logoutUser } from "../../../redux/ActionCreators";


function Sidebar() {
  const navigate = useHistory()
  useEffect(()=>{
    sidebarFunctionality()
  })

    const sidebarFunctionality=()=>{
      /* eslint-disable */
        "use strict";
        $("#sidebarToggle, #sidebarToggleTop").on("click", function (e) {
            $("body").toggleClass("sidebar-toggled"), $(".sidebar").toggleClass("toggled"), $(".sidebar").hasClass("toggled") && $(".sidebar .collapse").collapse("hide");
        }),
        $(window).resize(function () {
          $(window).width() < 768 && $(".sidebar .collapse").collapse("hide"),
          $(window).width() < 480 && !$(".sidebar").hasClass("toggled") && ($("body").addClass("sidebar-toggled"), $(".sidebar").addClass("toggled"), $(".sidebar .collapse").collapse("hide"));
            }),
            $("body.fixed-nav .sidebar").on("mousewheel DOMMouseScroll wheel", function (e) {
                var o;
                768 < $(window).width() && ((o = (o = e.originalEvent).wheelDelta || -o.detail), (this.scrollTop += 30 * (o < 0 ? 1 : -1)), e.preventDefault());
            }),
            $(document).on("scroll", function () {
                100 < $(this).scrollTop() ? $(".scroll-to-top").fadeIn() : $(".scroll-to-top").fadeOut();
            });
           
    }

    const logoutAdmin = () => {
      logoutUser()
      localStorage.clear();
      navigate.push('/')

    }
      

    return(
        <>

             {/* Sidebar */}
          <ul className="navbar-nav shadow sidebar sidebar-dark accordion zi-1 pb-100" id="accordionSidebar">
            {/* Sidebar - Brand */}
            <Link className="sidebar-brand d-flex align-items-center justify-content-center mt-2" to="/">
              
              <div className="sidebar-brand-text mx-3">
                <img src={logo} className="w-100" alt="apollo" />
              </div>
              <div className="mini mx-3">
                <img src={logoMini} className="w-100" alt="apollo" />
              </div>
            </Link>
           
            {/* sidebar links */}
            <li className="nav-item my-1 mt-5">
              <NavLink className="nav-link sidebar-Link fw-500" to="/dashboard">
              <img className="" src={home} alt="" />
                <span className="">Dashboard</span></NavLink>
            </li>
            <li className="nav-item my-1">
              <NavLink className="nav-link sidebar-Link fw-500" to="/pair">
                <img className="" src={load} alt="" />
                <span className="">Pair</span></NavLink>
            </li>
            <li className="nav-item my-1 ">
              <NavLink className="nav-link sidebar-Link fw-500" to="/round">
              <img className="" src={drivers} alt="" />
                <span className="">Round</span></NavLink>
            </li>
          
            <li className="nav-item my-1">
              <a className="nav-link sidebar-Link fw-500" href="#">
              <img className="" src={setting} alt="" />
                <span className="">Settings</span></a>
            </li>
           
          
            {/* Nav Item - Pages Collapse Menu */}
            {/* <li className="nav-item">
              <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                <i className="fas fa-fw fa-cog" />
                <span>Components</span>
              </a>
              <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
                <div className="bg-white py-2 collapse-inner rounded">
                  <h6 className="collapse-header">Custom Components:</h6>
                  <a className="collapse-item" href="buttons.html">Buttons</a>
                  <a className="collapse-item" href="cards.html">Cards</a>
                </div>
              </div>
            </li> */}
           
           <li className="logout-li">
               <a className="logout-div" onClick={logoutAdmin}>
                <img className="img-profile rounded-circle" src={profile} />
                <div className="profile-div align-self-center">
                  <span className="status ">Logout</span>
                </div>
              </a>
           </li>
          </ul>
          {/* End of Sidebar */}

        </>
    )
    
}
export default Sidebar