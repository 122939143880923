import React from 'react'
import {useHistory} from 'react-router-dom'
import {Button, Modal} from 'react-bootstrap';

export const AlertModal = ({handleClose,show,msg,checkStatus}) => {
  const history = useHistory()
  const navigateToRound = () => {
    history.push('/userround')
    handleClose()
  }

  return (
    <>
    <Modal show={show} onHide={handleClose} centered>
       
       <Modal.Body>
         <div className="WalletModal">
           <h1 className='my-5'>{msg}</h1>
           <div className="closeIcon" onClick={handleClose}>
           <i class="fas fa-times"></i>
           </div>
           {checkStatus && 
           <div className="btnDiv">
              <button className="" onClick={()=> navigateToRound()} >Check Status</button>
            </div>
           }
         </div>
       </Modal.Body>
       
    </Modal>
    </>
  )
}

export const PrivacyModal = ({handleClose,show}) => {

  return (
    <>
    <Modal show={show} onHide={handleClose} centered>
       
       <Modal.Body>
         <div className="WalletModal">
           <div className="closeIcon" onClick={handleClose}>
            <i class="fas fa-times"></i>
           </div>
          <div className="privacyPolicy mt-5">
            <h2 className='mb-4'>Privacy Policy for Analyzer</h2>
            <h6>Overview</h6>
            <p>Analyzer is a web application that helps users analyze their trading skills in the cryptocurrency market and predict the prices of different digital currencies. Our platform is built on Ethereum smart contract and deployed on the Binance mainnet, making it completely decentralized. We do not collect any personal information from users and we do not sell any user data to third parties</p>  
            <h6>Data Collection</h6>
            <p>We do not collect any personal information or data from users. Users connect to the platform via web3 wallets like Metamask, which does not reveal any user data to us. We use cookies to track user preferences and improve the user experience on the platform. We do not use cookies for any other purpose</p>
            <h6>Data Sharing</h6>
            <p>We do not share any user data with third parties. All transactions on the platform are visible on Binance mainnet. However, this information is anonymous and does not reveal any personal information about users.</p>
            <h6>Data Storage</h6>
            <p>We do not store any personal information or data from users on our servers. All data is stored on the Ethereum blockchain, making it completely decentralized. We do not have access to user accounts or funds.</p>
            <h6>Security</h6>
            <p>We take the security of our platform very seriously. The platform is built on an Ethereum smart contract which is secure and tamper-proof. We use HTTPS encryption to secure user connections to the platform. We regularly update our platform to address any security vulnerabilities that may arise. </p>
            <h6>Fees</h6>
            <p>We charge a 5% fee from every round played on the platform. This fee is automatically deducted from the round pool and is used to cover the cost of running and maintaining the platform.</p>
            <h6>Contact Us</h6>
            <p>If you have any questions or concerns about our privacy policy or the security of our platform, please contact us at support@analyzer.io. We will be happy to address your concerns and provide further information.</p>
          </div>           
         </div>
       </Modal.Body>
       
    </Modal>
    </>
  )
}

export const TermsModal = ({handleClose,show}) => {

  return (
    <>
    <Modal show={show} onHide={handleClose} centered>
       
       <Modal.Body>
         <div className="WalletModal">
           <div className="closeIcon" onClick={handleClose}>
            <i class="fas fa-times"></i>
           </div>
          <div className="privacyPolicy mt-5">
            <h2 className='mb-4'>TERMS & CONDITIONS for Analyzer Web App</h2>
            <p>Welcome to the Analyzer Web App, a decentralized platform designed to help you analyze your trading skills and predict the prices of different cryptocurrencies. The following Terms & Conditions outline the rules and regulations that govern the use of the Analyzer Web App. By using the Analyzer Web App, you agree to be bound by these Terms & Conditions</p>
            <h6>Use of Analyzer Web App</h6>
            <p className='mb-1'>1.1 The Analyzer Web App is intended for use by individuals who are at least 18 years of age or older. If you are under the age of 18, you are not permitted to use the Analyzer Web App.</p>
            <p className='mb-1'>1.2 To use the Analyzer Web App, you must connect via a web3 wallet such as Metamask and have access to the Binance Mainnet.</p>
            <p>1.3 It is your responsibility to ensure that your use of the Analyzer Web App complies with all applicable laws, rules, and regulations. </p>

            <h6>Analyzer Rounds</h6>
            <p className='mb-1'>2.1 When you participate in an Analyzer round, you must put your stake in the round pool to predict the price of a given cryptocurrency.</p>
            <p className='mb-1'>2.2 The price of the cryptocurrency can go up or down during the round, and if you correctly predict the price, you will win a portion of the round pool.</p>
            <p className='mb-1'>2.3 The number of winners and distribution of rewards will be determined by the Analyzer Web App, and the funds will be distributed directly but you have to claim it via your connected wallet.</p>
            <p className='mb-1'>2.4 Analyzer rounds are completely decentralized, meaning that all transactions occur on the Ethereum smart contract deployed on Binance Mainnet.</p>
            <p>2.5 A 5% fee will be taken from the round pool, which will be distributed to the developers andmaintainers of the Analyzer Web App.</p>

            <h6>User Responsibility</h6>
            <p className='mb-1'>3.1 You are solely responsible for maintaining the security of your connected wallet, and the Analyzer Web App will not be held liable for any loss or theft of cryptocurrency.</p>
            <p className='mb-1'>3.2 You agree not to engage in any fraudulent or illegal activity while using the Analyzer Web App. Violation of this provision may result in account termination and forfeiture of any winnings.</p>
            <p className='mb-1'>3.3 You agree not to use the Analyzer Web App to conduct any activities that are prohibited by the rules and regulations of your jurisdiction.</p>
            <p>3.4 You acknowledge that the Analyzer Web App is provided "as is," without any warranties or representations, express or implied, regarding its suitability for any particular purpose.</p>
            
            <h6>Intellectual Property</h6>
            <p>4.1 All content on the Analyzer Web App is owned by the developers and maintainers of the platform and/or their respective licensors. You agree not to copy, modify, or distribute any content on the Analyzer Web App without prior written consent from the owner</p>
            <h6>Modification of Terms & Conditions</h6>
            <p className='mb-1'>5.1 The developers and maintainers of the Analyzer Web App reserve the right to modify these Terms & Conditions at any time without prior notice</p>
            <p>5.2 It is your responsibility to periodically review the current Terms & Conditions. Your continued use of the Analyzer Web App after any changes to these Terms & Conditions constitutes your agreement to the modified terms.</p>
            <h6>Limitation of Liability</h6>
            <p className='mb-1'>6.1 To the maximum extent permitted by law, the Analyzer Web App and its developers and maintainers shall not be liable for any loss, damage, or injury caused by your use of the Analyzer Web App</p>
            <p>6.2 In no event shall the total liability of the Analyzer Web App and its developers and maintainers exceed the amount you have staked in the round pool.</p>
            <p className='mt-4'>By using the Analyzer Web App, you acknowledge that you have read and understood these Terms & Conditions, and you agree to be bound by them.</p>
         </div>           
         </div>
       </Modal.Body>
       
    </Modal>
    </>
  )
}
