import React,{useEffect,useState} from 'react';
import { NavLink } from "react-router-dom";
import axios from "axios";
import moment from "moment-timezone";
import Modal from 'react-bootstrap/Modal';

import { useDispatch, useSelector } from 'react-redux';
import {tenupPredictionContract,PredictionAbi} from "../../contractAbi"
import web3 from "../../web3"

import rightArrow from "../../images/right-arrow.svg";
import profile from "../../images/profile.jpg";
import edit from "../../images/edit.svg";
import Delete from "../../images/delete.svg";
import plus from "../../images/plus.svg";
import { driverData } from "./driversData";
import {serverPath} from "../../config"
import TenupIcon from "../../images/Tenup-Icon.png"





function Drivers({match}) {

  // console.log(window.location);
  // console.log(match.url);



    return(
      <>
                  {/* Begin Page Content */}
                    <div className="container-fluid">
                     {/* Content Row */}
                      <div className="row">
                      {/* Area Chart */}
                      <div className="col">
                         <div className="row mx-0 drivers-row">
                             <div className="col-xl-6 col-md-7  d-flex justify-content-between pr-0">
                                 <h4 className="color-dark fs-17 dib font-weight-bold mr-5">Round (124)</h4>
                                 {/* <a href="#" className="driver-row-link">All</a>
                                 <a href="#" className="driver-row-link">Arbitrators (86)</a>
                                 <a href="#" className="driver-row-link">Mediators (32)</a> */}
                             </div>
                          
                         </div>
                         {/* table */}
                         <Table />
                         <NavLink to="/createRound" className="diverAddBtn"><img src={plus} alt="" /></NavLink>

                      </div>
                      
                    </div>
              
                  </div>
                  {/* /.container-fluid */}
         
      </>
    )
  }
  const Table=()=>{
    const authedUser = useSelector(s => s.authedUser.authedUser.address);

    const [data,setData]=useState(driverData)
    const [currentPrice,setCurrentPrice]=useState("")
    const [currentRoundId,setCurrentRoundId]=useState("")
    const [currentLockPrice,setCurrentLockPrice]=useState("")
    const [currentResultTime,setCurrentResultTime]=useState("")

    const [currentTime,setCurrentTime]=useState(moment(new Date()).format("X") )
    const [show, setShow] = useState(false);
    const [loader,setLoader]=useState(false)



    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    async function getPairData() {
      await axios.get(`${serverPath}/round`)
      .then((res)=>{
        // console.log(res.data);
        setData(res.data)
      })
      .catch((res)=>{
        console.log(res);
      })
    }
    const getEndRoundId = async (roundId,resultT,lPrice) =>{
    //  console.log('====================================');
    //  console.log(roundId);
    //  console.log('====================================');
     setShow(true)
     setCurrentRoundId(roundId)
     setCurrentResultTime(resultT)
     setCurrentLockPrice(lPrice)
    }

    const endRound = async () =>{
      var currentTime =new Date()
      var currentTimestamp=moment(currentTime).format("X")
      // console.log('====================================');
      // console.log(currentTimestamp);
      // console.log(currentResultTime);
      // console.log('====================================');
     var PredContract = new web3.web3.eth.Contract(PredictionAbi, tenupPredictionContract );

      let currentRoundId1 = parseFloat(currentRoundId)
      let currentPrice1 = parseFloat(currentPrice)
      if(currentTimestamp > currentResultTime){

          if(authedUser.length > 0){
            if(!isNaN(currentPrice1) ){
              // console.log(!isNaN(currentPrice1));
              // console.log(currentRoundId1);
              // console.log(currentPrice1);
    
            try{
              setLoader(true)
      
              var result= await PredContract.methods.endRound(currentRoundId1,currentPrice1).send({from:authedUser})
              // console.log(result);
                  if(result.status){          
                    updateToDb(currentRoundId1,currentPrice1)
                    // setLoader(false)
                  }else{
                    alert("transaction not completed")
                    setLoader(false)
      
                  }
            }catch{
                console.log("something went wrong");
                    setLoader(false)
            }
    
          }else {
            alert("invalid Price")
          }
        }else {
          alert("please connect wallet")
        }
      }else {
        alert("This round result time in future, you can not end")
      }
    }

    const updateToDb = async (roundId,resultPrice) => {
     var PredContract = new web3.web3.eth.Contract(PredictionAbi, tenupPredictionContract );

      var result= await PredContract.methods.getRoundData(parseInt(roundId)).call()
      // console.log(result);
      // console.log("result");
       let up = parseFloat(result.updown_amounts[0])//2
       let down = parseFloat(result.updown_amounts[1])//0
       let winResult = currentLockPrice < resultPrice ? true: false

      let sendData = {
        roundID:roundId,
        resultPrice:resultPrice, 
        totalUp:up,
        totalDown:down,
        winResult,winResult
      }

      await axios.put(`${serverPath}/round`,sendData,{
        headers: {
          'Content-Type': 'application/json',
      }
      })
      .then((res)=>{
        // console.log(res);
            getPairData()
            setLoader(false)
            handleClose()
       
      })
      .catch((res)=>{
        console.log(res);
      })
    }
  
    useEffect(()=>{
      getPairData()
      // console.log(currentTime);
      
    },[])

    return(
      <>
      <div className="card sortby-box br-10 border-transparent mt-3"> 
        <div className="card-header br-10 table-responsive  bg-white border-transparent py-3 d-flex flex-row align-items-center justify-content-between">
          <table class="table table-hover drivers-table">
            <thead>
              <tr>
                <th scope="col" className="text-uppercase">Round Id</th>
                <th scope="col" className="text-uppercase">Pair Id</th>
                <th scope="col" className="text-uppercase">Pair Name</th>
                <th scope="col" className="text-uppercase">Start Time</th>
                <th scope="col" className="text-uppercase">Predict Diff. in Minutes</th>
                <th scope="col" className="text-uppercase">Result Time</th>
                <th scope="col" className="text-uppercase">Locked Price</th>
                <th scope="col">Status</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {data.map((row,i)=>(

              <tr>
                <td scope="row" className="d-flex">
                  <div className="table-text-dark text-uppercase">{row.roundID}</div>
                </td>
                <td>
                  <span className="table-text-dark w-max-c db">{row.pairId}</span>
                </td>
                <td>
                  <span className="table-text-dark w-max-c db">{row.pairName}</span>
                </td>
                <td>
                  <span className="table-text-dark w-max-c db">{moment.unix(row.startTime).format()}</span>
                </td>
                <td>
                  <span className="table-text-dark w-max-c db">{ row.predictionTime / 60}</span>
                </td>
                <td>
                  <span className="table-text-dark w-max-c db">{moment.unix(row.resultTime).format()}</span>
                </td>
                <td className="">
                  <span className="table-text-dark "> {row.lockPrice}</span>
                </td>
                <td>
                  {/* <span className="status-loaded ">{row.status}</span> */}
                  <span className=
                  {row.status == "ended" 
                  ? "status-ongoing driver-status-title w-max-c"
                  :"driver-status-title status-ended w-max-c"}>
                    {row.status != "ended" ?
                    <>
                      {currentTime < row.startTime?
                      "Not Started"
                      : currentTime > row.startTime && currentTime < (parseInt(row.startTime) + parseInt(row.predictionTime) ) ? 
                      "Started"
                      :
                      "Prediction time end"
                      }
                    </>
                    :"Ended"
                    }
                  
                  </span>
                </td>
                <td className="">
                {row.status == "ended"?
                  <button className='btn btn-dark' >Ended</button>
                :
                  <button className='btn btn-dark' onClick={()=>getEndRoundId(row.roundID,row.resultTime,row.lockPrice)} >End Round</button>
                }
                </td>
               
                <td>
                  <div className="mr-5">
                    <a href="#" className="mr-3"><img src={edit} alt="edit" /></a>
                    <a href="#"><img src={Delete} alt="Delete" /></a>
                  </div>
                
                </td>
              </tr>
              ))}
            
          
            </tbody>
          </table>
      </div>

    </div>
    <Modal show={show} onHide={handleClose}>
        
        <Modal.Body>
          <div className="pairModal">
              <div className="row1">
                <h2>End Round</h2>
                <div className="close" onClick={handleClose} ><i class="fas fa-times-circle"></i></div>
              </div>
              <div>
                <input type="text" name="roundId" value={currentRoundId} disabled placeholder="Round Id" className="form-control theme-bg-color border-0 small mb-2" />
                <input type="text" name="price" value={currentPrice} onChange={(e)=> setCurrentPrice(e.target.value)} placeholder="End Price" className="form-control theme-bg-color border-0 small" />
              </div>
              <div className="btn submitBtn" onClick={endRound}>Submit</div>
          </div>
        </Modal.Body>
     
      </Modal>
      {loader &&
          <div className="seekoLoader">
              <div className="loader">
                  <img src={TenupIcon} alt="loader" />
              </div>
          </div>
      }
      </>
    )
  }

  export default Drivers