import React,{useEffect,useState} from 'react';
import { Link } from "react-router-dom";
import axios from "axios";
import {serverPath} from "../../config"
import moment from "moment-timezone";

import {LineChart,Line,XAxis,YAxis,CartesianGrid,Tooltip,Legend,ReferenceLine,ResponsiveContainer,} from 'recharts';

import revenue from "../../images/revenue.svg";
import truck from "../../images/truck.svg";
import averageRate from "../../images/average-rate.svg";
import loadMove from "../../images/load-move.svg";
import kebab from "../../images/kebab.svg";
import profile from "../../images/profile.jpg";
import pair from "../../images/pair.png";
import totalRound from "../../images/totalRound.png";
import round from "../../images/round.png";


// import axios from "axios";



// js

// import Navbar from './navbar';
// import Sidebar from './sidebar';

function Dashboard(props) {
  const [currentTime,setCurrentTime]=useState(moment(new Date()).format("X") )

  const [pairData,setPairData]= useState(0)
  const [RoundLive,setRoundLive]= useState([])
  const [RoundData,setRoundData]= useState([])
  const redColor={
    color:'red'
  }
  const greenColor={
    color:'#28CB89'
  }

  
  async function getPairData() {
    await axios.get(`${serverPath}/dashboard`)
    .then((res)=>{
      setPairData(res.data.pair)
      setRoundData(res.data.round)
      var live=res.data.round.filter(e=>currentTime > e.startTime && currentTime < (parseInt(e.startTime) + parseInt(e.predictionTime) ) )
      // console.log(live);
      setRoundLive(live)
    })
    .catch((res)=>{
      console.log(res);
    })
  }

  useEffect(()=>{
    getPairData()
  },[])

  return(
    <>
                {/* Begin Page Content */}
                <div className="container-fluid">
                  
                    {/* Content Row */}
                    <div className="row">
                      {/* Earnings (Monthly) Card Example */}
                      <AnalyticsBox icon={revenue} title="Total Revenue"  amount="$112,705" lastMonthAmount="Over last month: $15,600" />
                      <AnalyticsBox icon={pair} title="Number of Pair"  color={redColor} amount={pairData}  />
                      <AnalyticsBox icon={round} title="Ongoing Round"  amount={RoundLive.length}   />
                      <AnalyticsBox icon={totalRound} title="Total Round" amount={RoundData.length}  />

                    </div>
                    
                    {/* Content Row */}
                    <div className="row">
                    {/* Area Chart */}
                    <div className="col-xl-8 col-lg-7 mb-4">
                      <div className="card br-10 h-100 border-transparent overflow-hidden">
                        {/* Card Header - Dropdown */}
                        <div className="card-header bg-white border-transparent py-3 d-flex flex-row align-items-center justify-content-between">
                          <h6 className="m-0 chart-heading">Revenue Generated</h6>
                          <div className="dropdown no-arrow">
                              <a href="#" className="chart-time chart-time-active pr-4">All time</a>
                              <a href="#" className="chart-time pr-4">This year</a>
                              <a href="#" className="chart-time pr-4">This week</a>

                              <a className="dropdown-toggle px-2" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <img src={kebab} className="w-4" alt="dropdown icon" />
                              </a>
                              <div className="dropdown-menu dropdown-menu-right shadow animated--fade-in" aria-labelledby="dropdownMenuLink">
                                <a className="dropdown-item" href="#">Action</a>
                                <a className="dropdown-item" href="#">Another action</a>
                                <div className="dropdown-divider" />
                                <a className="dropdown-item" href="#">Something else here</a>
                              </div>
                          </div>
                        </div>
                        {/* Card Body */}
                        <div className="card-body">
                          <div className="chart-area">
                              <RevenueChart />                    

                          </div>
                        </div>
                      </div>
                    </div>
                    
                    {/* diver status */}
                    <div className="col-xl-4 col-lg-5 mb-4">
                      <div className="card h-100 mb-4 br-10 border-transparent overflow-hidden">
                        {/* Card Header - Dropdown */}
                        <div className="card-header bg-white border-transparent py-3 d-flex flex-row align-items-center justify-content-between">
                          <h6 className="m-0 diver-name">Round Name</h6>
                          <div>
                            <span className="driver-status-title">Status</span>
                          </div>
                        </div>

                        {/* Card Body */}
                        <div className="card-body ">
                          {/* driver card row */}
                          { RoundData.slice(0,5).map((item, key) => (
                            <DriverStatusRow RoundName={item?.pairName} pairId={item?.roundID} cTime={currentTime} sTime={parseInt(item.startTime)} pTime={parseInt(item.predictionTime)} status={
                              item.status != "ended" ?
                              <>
                                {currentTime < item.startTime?
                                "Not Started"
                                : currentTime > item.startTime && currentTime < (parseInt(item.startTime) + parseInt(item.predictionTime) ) ? 
                                "Started"
                                :
                                "Prediction time end"
                                }
                              </>
                              :"Ended"
                              
                            } />
                          ))}
                     
                        </div>
                      </div>
                    </div>
                  </div>
            
                </div>
                {/* /.container-fluid */}
       
    </>
  )
}

function AnalyticsBox(props) {
  return(
    <div className="col-xl-3 col-md-6 mb-4">
    <div className="card h-100 py-2 border-transparent br-10">
      <div className="card-body">
        <div className="row no-gutters align-items-center">
          <div className="col">
            <img className="analytic-box-img" src={props.icon} alt="" />
          </div>
          <div className="col-auto mr-2">
            <div className="text-xs font-weight-bold  mb-1">
              <span className="analytics-box-title">{props.title}</span> <span className="analytic-percentage ml-2" style={props.color}>{props.percent}</span>
            </div>
            <div className="h4 mb-0 font-weight-bold text-gray-800">{props.amount}<span className="mile">{props.mile}</span></div>
            <div>
              <span className="last-month">{props.lastMonthAmount}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
  
}

function DriverStatusRow(props) {
  return(
      <div className="d-flex py-2 driver-dash-div flex-row align-items-center justify-content-between">
        <div className="d-flex">
            <div className="profile-div ml-2">
              <span className="mr-2 fs-14 small profile-name text-uppercase">{props.RoundName}</span>
              <span className="status fs-10 font-weight-bold dark-font">{props.pairId}</span>
            </div>
        </div>
        <div>
          {/* this condition check status and set specified color */}
                                     
          <div className='font-bold'>{props.status}</div>
   
        </div>
     </div>
  )
  
}

function RevenueChart() {
  const data = [
    {
      name: 'Jan',
      uv: 40,
      pv: 24,
      amt: 2400,
    },
    {
      name: 'Feb',
      uv: 10,
      pv: 13,
      amt: 2210,
    },
    {
      name: 'Mar',
      uv: 90,
      pv: 98,
      amt: 2290,
    },
    {
      name: 'Apr',
      uv: 120,
      pv: 110,
      amt: 2000,
    },
   
    {
      name: 'May',
      uv: 100,
      pv: 90,
      amt: 2500,
    },
    {
      name: 'Jun',
      uv: 34,
      pv: 43,
      amt: 2100,
    },
    {
      name: 'Jul',
      uv: 34,
      pv: 43,
      amt: 2100,
    },
    {
      name: 'Aug',
      uv: 34,
      pv: 43,
      amt: 2100,
    },
    {
      name: 'Sep',
      uv: 44,
      pv: 53,
      amt: 2100,
    },
    {
      name: 'Oct',
      uv: 84,
      pv: 93,
      amt: 2100,
    },
    {
      name: 'Nov',
      uv: 44,
      pv: 43,
      amt: 2100,
    },
    {
      name: 'Dec',
      uv: 34,
      pv: 53,
      amt: 2100,
    },
  ];
  return(
    // <ResponsiveContainer width="100%" height="100%">
    <LineChart width={600} height={300} data={data} className="w-100 h-100">
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" padding={{ left: 30, right: 30 }} />
      <YAxis />
      <Tooltip />
      <Line type="monotone" dataKey="pv" stroke="#E703EE" stroke-width="2" activeDot={{ r: 8 }} />
      <Line type="monotone" dataKey="uv" stroke="#cad1da" stroke-width="2" />
    </LineChart>
  // </ResponsiveContainer>

  
  )
  
}


export {Dashboard};
