import React,{useEffect, useState} from "react";
import Web3 from "web3";
import web3 from '../../../web3';
import { bscId, ethId, serverPath } from "../../../config.js";
import { loginUser, loginUserReq } from "../../../redux/ActionCreators";
import { useDispatch, useSelector } from 'react-redux';
import { AlertModal } from "../../../componentUser/alertModal";

function Navbar() {
  const dispatch = useDispatch();
  const authedUser = useSelector(s => s.authedUser.authedUser);

  const [isConnected,setisConnected] = useState(false);
  const [activeAccount,setActiveAccount] = useState(null);
  const [activeBalance,setBalance] = useState(null);
  const [ID,setId] = useState(null);

  const [showMsg, setShowMsg] = useState(false);
  const [msgText, setMsgText] = useState("");
  const handleCloseMsg = () => setShowMsg(false);
  const handleShowMsg = () => setShowMsg(true);
  
  // const logingHandler = async ()=>{

  //   if (!window.ethereum){
  //     alert('Install Metamask');
  //   }
  //   else if (parseInt(window.ethereum.chainId) !== parseInt(bscId) ){
  //     web3.web3 = new Web3(window.ethereum);
      
  //     console.log(window.ethereum.chainId);
  //     console.log(await web3.web3.eth.getChainId());
  //     alert('Connect to BSC');
  //   }
  //   else{
  //     web3.web3 = new Web3(window.ethereum);
  //     let accs = await window.ethereum.request({
  //       method: 'eth_requestAccounts'
  //     });

  //     if (accs.length > 0){
  //       let ad = accs[0].toLowerCase();
  //       console.log(accs);
       
  //       let b = await web3.web3.eth.getBalance(accs[0]);
  //       // setBalance(balance)
        
  //       setActiveAccount(accs[0]);
  //       setisConnected(true)
  //       setBalance(b);
  //       setId((parseInt(window.ethereum.chainId)).toString());
  //       dispatch(loginUser(accs[0]));
         
  //     }
  //   }
  //   // setisConnecting(false)
  // }
  const checkIfUserExists = (address) => { return fetch(`${serverPath}/users/exists/${address}`).then(r => r.json())}

  const jwtValid = (token, address) => {
    return fetch(`${serverPath}/users/check-jwt/${address}`, {
      method: 'GET',
      headers: {
        'Authorization' : `Bearer ${token}`
      }
    }).then(r => {
      // console.log(r);
      if (r.ok){
        return r.json();
      }
      else{
        return {success: false};
      }
    });
  }
  
  const verifySignature = (signature, address) => {
    // console.log(signature);
    // console.log(address);
    return fetch(`${serverPath}/users/login`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        signature,
        address
      })
    }).then(r => r.json())
  }

  const logingHandler = async ()=>{

    if (!window.ethereum){
      // alert('Install Metamask');
      handleShowMsg()
      setMsgText("Install Metamask")
    }
    else if (parseInt(window.ethereum.chainId) !== parseInt(bscId)){
      web3.web3 = new Web3(window.ethereum);
      
      // console.log(window.ethereum.chainId);
      // console.log(await web3.web3.eth.getChainId());
      // alert('Connect to ETH or BSC');
      handleShowMsg()
      setMsgText("Connect to ETH or BSC")
    }
    else{
      web3.web3 = new Web3(window.ethereum);
      let accs = await window.ethereum.request({
        method: 'eth_requestAccounts'
      });

      if (accs.length > 0){
        let ad = accs[0].toLowerCase();
        let noToken = true;
        // console.log(accs);
        let r = await checkIfUserExists(ad);
        // console.log(r);
        if (localStorage.getItem(ad.toLowerCase())){
          let valid = await jwtValid(localStorage.getItem(ad.toLowerCase()), ad.toLowerCase());
          // console.log(valid);
          if (valid.success){
            valid.user.token = localStorage.getItem(ad.toLowerCase());
            dispatch(loginUser(valid.user));
            noToken = false;
            let b = await web3.web3.eth.getBalance(accs[0]);
            // setBalance(balance)
            setActiveAccount(accs[0]);
            setisConnected(true)
            setBalance(b);
            setId((parseInt(window.ethereum.chainId)).toString());
            
          }
          else{
            localStorage.removeItem(ad);
          }
          }
        
        if (noToken){
          let signature, signed = true;
          try{
            signature = await web3.web3.eth.personal.sign(
              `I am signing my one-time nonce: ${r.user.nonce}`,
              accs[0],
              ''
            );  
          }
          catch(e){
            console.log(e);
            signed = false;
            // alert('Need to sign signature to continue.');
            handleShowMsg()
            setMsgText("Need to sign signature to continue.")
          }
          if (signed){
            // console.log(signature);
            // console.log(ad);
            let res = await verifySignature(signature, ad);
            // console.log(res);
            if (res.success){
              r.user.token = res.token;
              dispatch(loginUser(r.user));
              setActiveAccount(accs[0]);
              let b = await web3.web3.eth.getBalance(accs[0]);
              setisConnected(true)
              setBalance(b);
              setId((parseInt(window.ethereum.chainId)).toString());
              // getContract(web3.web3,accs[0],b,(parseInt(window.ethereum.chainId)).toString())  
            }
            else{
              // alert('Invalid Signature');
              handleShowMsg()
              setMsgText("Invalid Signature")
            }
          } 
        }
      }
    }
    // setisConnecting(false)
  }

  useEffect(()=>{
    console.log(authedUser.address);
  },[authedUser])


    return(
        <>
             {/* Topbar */}
             <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top">
                {/* Sidebar Toggle (Topbar) */}
                <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
                  <i className="fa fa-bars" />
                </button>
              
                {/* Topbar Navbar */}
                <div className="walletBtn ml-auto">
                  <button className="btn " onClick={logingHandler}>
                  {authedUser?.address ? 
                  <> {authedUser.address.slice(0,5)}...{authedUser.address.slice(38,authedUser.address.length)} </>
                  :"Wallet"}  
                  </button>
                </div>
             
              </nav>
              {/* End of Topbar */}
          <AlertModal show={showMsg} handleClose={handleCloseMsg} msg={msgText} />

        </>
    )
    
}
export default Navbar