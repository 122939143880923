import React, {useEffect, useState } from 'react';
import { useLocation,BrowserRouter,Redirect, BrowserRouter as Router,Route,Switch } from "react-router-dom";
import CreateRound from '../component/CreateRound/createRound';

import  {Dashboard}  from '../component/dashboard/dashboard';
import Drivers from '../component/drivers/drivers';
import Loads from '../component/loads/loads';

function AppRouter(){
    const Location = useLocation();

    return(
        <>
            {/* <Route exact path='/'  >
                <Redirect to="/" />
            </Route> */}
            <Route exact path='/' component={Dashboard} />
            <Route exact path='/pair' component={Loads} />
            <Route  path='/round' component={Drivers} />
            <Route  path='/createRound' component={CreateRound} />

            {Location.pathname != '/' && Location.pathname != '/pair' && Location.pathname != '/round' && Location.pathname != '/createRound' && 
                <Redirect to="/" />
            }

        </>
    )
}

export default AppRouter