import React, { useState,useEffect, useRef, useLayoutEffect } from 'react';
import axios from "axios";
import { useDispatch, useSelector } from 'react-redux';

import moment from "moment-timezone";
import { BigNumber } from 'bignumber.js';

import {serverPath} from "../config"
import {tenupPredictionContract,PredictionAbi, tenupErcContract, tenupErc20Abi} from "../contractAbi"
import web3 from "../web3"
import Web3 from 'web3';



import { useParams } from "react-router-dom";
import goingUp from "../images/userImages/goingUp.svg"
import goingDown from "../images/userImages/goingDown.svg"
import layer from "../images/userImages/layer.png"
import ball from "../images/userImages/ball.png"
import dougnut from "../images/userImages/dougnut.png"
import cross from "../images/userImages/cross.png"
import triangle from "../images/userImages/triangle.png"
import usdt from "../images/userImages/usdt.png"
import TenupIcon from "../images/Tenup-Icon.png"
import closeIco from "../images/closeIco.svg"

import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { AlertModal } from './alertModal';
import { Modal } from 'react-bootstrap';

const data = [
    {
        "name": 1,
        "uv": 0.10
    },
    {
        "name": 2,
        "uv": 0.50
    },
    {
        "name": 3,
        "uv": 0.10
    },
    {
        "name": 4,
        "uv": 0.5000
    }
]

export const Detail =() => {
    var bitcoinData;
    const ws = new WebSocket("wss://fstream.binance.com/ws");
  const authedUser = useSelector(s => s.authedUser.authedUser);
  const [currentTime,setCurrentTime]=useState(moment(new Date()).format("X") )
  var [currentPrice,setCurrentPrice]=useState(0)

  const [loader,setLoader]=useState(false)

  let { RoundId } = useParams();
  const [liveData, setLiveData] = useState([]);
  const [formData, setformData] = useState({
    prediction:true,
    amount:'',
    analysis:'',
  });

  const [checkStatus, setCheckStatus] = useState(false);

  const [showMsg, setShowMsg] = useState(false);
  const [msgText, setMsgText] = useState("");
  const [show, setShow] = useState(false);
  const [selectedSide, setSelectedSide] = useState("up");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleCloseMsg = () => {
    setShowMsg(false)
    setCheckStatus(false)
};
  const handleShowMsg = () => setShowMsg(true);


  const openSideModal = (side) => {
    if(side == "up"){
        handleForm(true)
    }else {
        handleForm(false)
    }
    setSelectedSide(side)
    handleShow(true)
  };



    async function getRoundData() {
        setLoader(true)
        await axios.get(`${serverPath}/currentRound/?roundID=${RoundId}`)
        .then((res)=>{
            // console.log(res.data);
            setLiveData(res.data)
          getRoundBlockchain(res.data)
        //   console.log(res.data[0].pairName);
          getCurrentPrice(res.data[0].pairName)
        })
        .catch((res)=>{
        //   console.log(res);
            setLoader(false)

        })
      }
    
      async function getRoundBlockchain(liveD) {
        if(liveD.length > 0){
            let web33 = new Web3(process.env.REACT_APP_NODE_URL_RPC_GET);

            var PredContract = new web33.eth.Contract(PredictionAbi, tenupPredictionContract );
            var erc20contractBsc = new web33.eth.Contract(tenupErc20Abi, tenupErcContract );

            // console.log(PredContract);
            // console.log(liveD);
            // if(authedUser.address){
        
              try{
                var merged=[]
          
                    var potentialRoi;
                    var userBalance;
                   var result= await PredContract.methods.getRoundData(RoundId).call()
                //    console.log(result);
                //    console.log("result");
                    let up = parseFloat(result.updown_amounts[0])
                    let down = parseFloat(result.updown_amounts[1])
                   var updownTotal = up + down
                   let PayoutratioUp= updownTotal ==0 ? 0: up > 0 ?  updownTotal/up : 0;
                   let PayoutratioDown= updownTotal ==0 ? 0 : down > 0 ? updownTotal/down : 0 ;
            
                    if(PayoutratioUp > PayoutratioDown){
                      potentialRoi = PayoutratioUp
                    }else if(PayoutratioUp < PayoutratioDown){
                      potentialRoi = PayoutratioDown
                    }else if(PayoutratioUp==0 && PayoutratioDown==0){
                      potentialRoi=0
                    }

                    if(authedUser.address){
                        var ubalance1 = await erc20contractBsc.methods.balanceOf(authedUser.address).call()
           
                        userBalance = ubalance1? ubalance1 / 1000000000000000000 : 0
                    }else{
                        userBalance=0;
                    }
                    // console.log('====================================');
                    // console.log(userBalance);
                    // console.log('====================================');

                    
                    let sendData = {
                      potentialRoi: potentialRoi % 1 != 0 ? potentialRoi.toFixed(2) : potentialRoi,
                      price: updownTotal / 1000000000000000000,
                      totalUp: up / 1000000000000000000,
                      totalDown: down / 1000000000000000000,
                      roiUp: PayoutratioUp % 1 != 0 ? PayoutratioUp.toFixed(2) : PayoutratioUp ,
                      roiDown: PayoutratioDown % 1 != 0 ? PayoutratioDown.toFixed(2):PayoutratioDown,
                      userBalance:userBalance,
                    }
                    merged = [{...liveD[0], ...sendData}];
                
                    
                //   console.log(merged);
                  setLiveData(merged)
                    setLoader(false)
                  
          
               }catch{
                // console.log("something went wrong");
                setLoader(false)
                handleShowMsg()
                setMsgText("You have analyzed it.")
                setCheckStatus(true)
               }
            // }
        }else{
            // alert("no data about this Round Id")
            setLoader(false)
        }
      }
      const getCurrentPrice = async(pairName) =>{
        let c1=pairName.split('/')[0].toUpperCase()
        let c2=pairName.split('/')[1].toUpperCase()
        // console.log(c1);
        await axios.get(`https://api.blockchain.com/v3/exchange/tickers/${c1}-${c2}`)
        .then((res)=>{
        //   console.log(res.data?.last_trade_price);
          if(res.data?.last_trade_price){
            setCurrentPrice(res.data?.last_trade_price)
          }else{
            setCurrentPrice(0)
          }
          
        })
        .catch((res)=>{
          console.log(res);
          setCurrentPrice(0)
        })
      }
    
      useEffect(()=>{
        
        getRoundData()
        
      },[])


      function handleForm(e) {
        if(e.target){
            setformData({...formData,[e.target.name]:e.target.value})
        }else{
            
            setformData({...formData,prediction:e})
        }
      }
      
      function handleToggle(e ,val) {      
          let downId =document.getElementById('downclick')
          let upId =document.getElementById('upclick')
          let upCollapse =document.getElementById('goingUp')
          
            // console.log(downId);
            // console.log(upCollapse.classList.contains('show'));

            if(upCollapse.classList.contains('show') && val){
                // up
                downId.click()
                setformData({...formData,prediction:false})
            }
            else if(!upCollapse.classList.contains('show') && val){
                setformData({...formData,prediction:true})
                downId.click()

            }
            else if(upCollapse.classList.contains('show') && !val){
                setformData({...formData,prediction:false})
                upId.click()

            }
            else if(!upCollapse.classList.contains('show') && !val){
                setformData({...formData,prediction:true})
                upId.click()
            }
            else{
                // down
                
                // upId.click()
                // upId.click()
            }
      }

   
 
      async function formSubmit() {
        var PredContract = new web3.web3.eth.Contract(PredictionAbi, tenupPredictionContract );
         var erc20contractBsc = new web3.web3.eth.Contract(tenupErc20Abi, tenupErcContract );
         
         if(authedUser.address){
             // ==========
             if(formData?.amount <= 0){
                handleShowMsg()
                setMsgText("Please enter invest amount and submit.")
                return
            }
            if(currentTime < parseInt(liveData[0]?.startTime)){
                // alert("Please wait to start prediction")
                handleShowMsg()
                setMsgText("Please wait to start analyzer")
                
            }else if(currentTime > parseInt(liveData[0]?.startTime) + parseInt(liveData[0]?.predictionTime)){
                // alert("Prediction time end")
                handleShowMsg()
                setMsgText("Analyze time end")
            }
             else{
                var checkAlreadyPridict = await PredContract.methods.userData( parseInt(RoundId),authedUser.address).call()
                    // console.log(checkAlreadyPridict);
                if(checkAlreadyPridict.amount == '0'){
                   
                    if((formData.amount && formData.amount != '' ) && typeof formData.prediction === 'boolean'){
       
                       var ubalance1 = await erc20contractBsc.methods.balanceOf(authedUser.address).call()
       
                       var ubalance = ubalance1? ubalance1 / 1000000000000000000 : 0
                       let sendData={
                           roundID: parseInt(RoundId),
                           upDown:formData.prediction,
                           amount:parseFloat(formData.amount)
                       }
                    //    console.log(sendData.amount);
                    //    console.log(ubalance);
       
                       if(ubalance >= sendData.amount ){
                           setLoader(true)
                           let tokenAmount = parseFloat(formData.amount) * 1000000000000000000;
                           tokenAmount = new BigNumber(tokenAmount);
                           let allow =await erc20contractBsc.methods.allowance(authedUser.address,tenupPredictionContract).call()
                           // console.log('====================================');
                        //    console.log( allow);
                        //    console.log( sendData.amount);
                        //    console.log(ubalance );
                           // console.log('====================================');
                           
                           if(allow / 1000000000000000000 >= sendData.amount ){
                               handlePredict()
                           }else{
                           setLoader(true)
                           try{
                               erc20contractBsc.methods.approve(tenupPredictionContract,tokenAmount.toFixed()).send({from:authedUser.address})
                               .then(async(res)=>{
                                //    console.log(".then");
                                //    console.log(res);
                                   if(res.status){
                                       // setLoader(false)
           
                                   handlePredict()
                                   }
                                   
                               }).catch((res)=>{
                                   console.log(res);
                                   setLoader(false)
                               })
                           }catch{
                            //    alert("something went wrong");
                                 setLoader(false)
                                 handleShowMsg()
                                 setMsgText("something went wrong")
                           }
                           }
                       }else {
                        //    alert("you do not have enough amount")
                           handleShowMsg()
                           setMsgText("you do not have enough amount")
                       }
                     
                       }else{
                    //    alert("Please Enter Invest Amount and press up or down")
                       handleShowMsg()
                       setMsgText("Please Enter Invest Amount and press submit.")
                       }
                }
                else{
                    // alert("You have analyzed already.")
                    handleShowMsg()
                    setMsgText("You have analyzed already.")
                }
            }
            // ==============
        }else {
            // alert("please connect wallet")
            handleShowMsg()
            setMsgText("please connect wallet")
        }
      } 

      async function handlePredict() {
                var PredContract = new web3.web3.eth.Contract(PredictionAbi, tenupPredictionContract );
                let tokenAmount = parseFloat(formData.amount) * 1000000000000000000;
                tokenAmount = new BigNumber(tokenAmount);
                // console.log('====================================');
                // console.log(tokenAmount);
                // console.log('====================================');
                let sendData={
                    roundID: parseInt(RoundId),
                    prediction:formData.prediction,
                    amount:tokenAmount.toFixed(),
                    analysis:formData.analysis
                }
                // console.log(sendData);
              try{
                    setLoader(true)
            
                    var result= await PredContract.methods.predict(sendData.roundID,sendData.prediction,sendData.amount).send({from:authedUser.address})
                    // console.log(result);
                        if(result.status){          
                          saveToDb()
                          setLoader(false)
                          getRoundData()
                          handleClose()
                        }else{
                        //   alert("transaction not completed")
                          setLoader(false)
                          handleShowMsg()
                          handleClose()
                           setMsgText("transaction not completed")
            
                        }
                  }catch{
                    //   alert("something went wrong");
                      handleShowMsg()
                      setMsgText("something went wrong")
                        setLoader(false)
                        handleClose()
                  }
      }

      async function saveToDb() {
        let sendData = {
            RoundId:liveData[0]?._id,
            userId: authedUser._id,
            prediction:formData.prediction,
            amount:formData.amount,
            analysis:formData.analysis,
            claimed:false,
            status:"invest",
        }
      
        // console.log(sendData);

        await axios.post(`${serverPath}/prediction`,sendData,{
            headers: {
              'Content-Type': 'application/json',
          }
          })
          .then((res)=>{
            // console.log(res);
            if(res.data.data._id){
                // alert("You have analyzed it.");
                handleShowMsg()
                setMsgText("You have analyzed it.")
                setLoader(false)
                setCheckStatus(true)
            // getPairData()
            }
          })
          .catch((res)=>{
            console.log(res);
          })
    }

    useEffect(() => {
        if(liveData[0]?.pairName){

            console.log(`${liveData[0]?.pairName.split('/')[0].toLowerCase() + liveData[0]?.pairName.split('/')[1].toLowerCase()}@markPrice@1s`);
            
            ws.onopen = (event) => {
                ws.send(
                  // JSON.stringify({
                  //   action: 'SubAdd',
                  //   subs: ['5~CCCAGG~BTC~USD'], // 0~Coinbase~BTC~USD
                  // }),
                  JSON.stringify({
                    method: 'SUBSCRIBE',
                    params: [`${liveData[0]?.pairName.split('/')[0].toLowerCase() + liveData[0]?.pairName.split('/')[1].toLowerCase()}@markPrice@1s`],
                    // params: [`btcusdt@markPrice@1s`],
                    // params: ['btcusdt@aggTrade'],
                    id: 1,
                  }),
                );
              };
           
              ws.onmessage = (data) => {
                const message = JSON.parse(data?.data);
                    // console.log(message);
                    
                if (message?.p && message?.T) {
                  bitcoinData = {
                    PRICE: Number(message?.p),
                    LASTUPDATE: message?.E,
                  };
                  setCurrentPrice(Number(message?.p))
                } else {
                  // console.log('BTC message = ', message);
                }
              };
            
            }
            return () => {            
                    if(bitcoinData?.PRICE) {
                    ws.close()
                   console.log("umount detal");
                   
                  }
                }
        }, [liveData])


     

  
        
    



    if(liveData.length > 0){

        return(
            <>
            <section className='DetailPage'>
                <img className='layer' src={layer} alt="" />
                <img className='triangle' src={triangle} alt="" />
                <div className="container pr">
                <img className='ball' src={ball} alt="" />
                <img className='dougnut' src={dougnut} alt="" />
                <img className='cross' src={cross} alt="" />
                    <div className="row">
                        <div className="col-12 order-xl-1 order-2">
                            <div className="headingRow">
                                <p className="mainHeading">What will be the <img className='w-30' src={liveData[0]?.pairIdRef?.tokenImage} alt="" />  {liveData[0]?.pairName.split('/')[0]} to {liveData[0]?.pairName.split('/')[1]} value at  {moment.unix(liveData[0]?.resultTime).format("dddd, MMMM Do YYYY, h:mm a")}? Will it go <img className='w-30 beatAnimation' src={goingUp} alt="" /> <span style={{color:"#19B000"}}>UP</span> or <img className='w-30 beatAnimation' src={goingDown} alt="" /> <span style={{color:"#FD003A"}}>DOWN</span> from 
                                {/* {liveData[0]?.lockPrice} */}
                                {currentPrice ? currentPrice.toFixed(2) : 0}
                                 ?</p>
                            </div>
                            <div className="analyzNewMain">
                                <div className="head">Analyze</div>
                                <div className="row">
                                    <div className="col-md-6 mb-3 mb-md-0">
                                        <div className="analyzCard" onClick={()=>openSideModal("up")}>
                                            <div className="col1">
                                                <h5>Going Up</h5>
                                                <p><span>{liveData[0]?.roiUp}x</span> Potential ROI</p>
                                                <p>≥ $ {liveData[0]?.lockPrice}</p>
                                               
                                            </div>
                                            <div className="col2">
                                                <div className='imgDiv'>
                                                    <img src={goingUp} alt="" />
                                                </div>
                                                <p>${liveData[0]?.totalUp} at stake</p>
                                                {/* <p className='plotPrice'>0.36 Tenup option price</p> */}
                                            </div>                                        
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="analyzCard" onClick={()=>openSideModal("down")}>
                                            <div className="col1">
                                                <h5>Going Down</h5>
                                                <p><span>{liveData[0]?.roiDown}x</span> Potential ROI</p>
                                                <p>&lt; $ {liveData[0]?.lockPrice}</p>
                                            </div>
                                            <div className="col2">
                                                <div className='imgDiv'>
                                                    <img src={goingDown} alt="" />
                                                </div>
                                                <p>${liveData[0]?.totalDown} at stake</p>
                                                {/* <p className='plotPrice'>0.36 Tenup option price</p> */}
                                            </div>                                        
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row2">
                                <div className="row">
                                    <div className="col-lg-5 mb-3 mb-lg-0">
                                        <div className="col1">
                                            <div className="nowDiv">
                                                <button className='now'>Current Price</button>
                                                <h6 className='price'>${currentPrice ? currentPrice.toFixed(2) : 0}</h6>
                                                {/* <h6 className='price'>${currentPrice}</h6> */}
                                            </div>  
                                            <div className="priceDiv">
                                                <h2>${liveData[0]?.price}</h2>
                                                <p className='font-capitalize'>total pool</p>
                                                {/* <p>{liveData[0]?.price} </p> */}
                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-lg-7">
                                        <div className="timeDivMM">
                                            <div className="btnMain">
                                            {currentTime < parseInt(liveData[0]?.startTime) ?
                                                    <button className='now mb-0'>Upcomming</button>
                                                : currentTime > liveData[0]?.startTime && currentTime < (parseInt(liveData[0]?.startTime) + parseInt(liveData[0]?.predictionTime)) ?
                                                    <button className='now mb-0'>Live</button>
                                                :
                                                <button className='now mb-0'>Pending</button>
                                            }
                                                <button className='weekly text-capitalize mb-0'>{liveData[0]?.type}</button>
                                            </div>
                                        {currentTime < parseInt(liveData[0]?.startTime) ?
                                        <>
                                            <Timer startTime={liveData[0]?.startTime} predictionTime="0" />
                                            <p>Starting in</p>
                                        </>
                                        : currentTime > liveData[0]?.startTime && currentTime < (parseInt(liveData[0]?.startTime) + parseInt(liveData[0]?.predictionTime)) ?
                                        <>
                                            <Timer startTime={liveData[0]?.startTime} predictionTime={liveData[0].predictionTime} />
                                            <p>time to participate</p>
                                        </>
                                        :
                                        <>
                                        {liveData[0]?.status == "ended"?
                                        <p>Ended</p>
                                        :
                                        <>
                                            <Timer startTime={liveData[0]?.resultTime} predictionTime="0" />
                                            {/* <p>Settling In</p> */}
                                        </>
                                        }
                                        </>
                                        }
                                        </div>
                                    </div>
                                </div>
                            </div>
    
                            <div className="analyticsCard">
                                {/* <div className="row1">
                                    <div className="col1">
                                        <div className="imgDiv">
                                            <img className='img-fluid' src={goingUp} alt="" />
                                        </div>
                                        <div className="title">
                                            <h5>Titan Price</h5>
                                            <p>1 Undefined = Undefined USD</p>
                                        </div>
                                    </div>
                                    <div className="col2">
                                            <select name="price" id="">
                                                <option value="">Price</option>
                                            </select>
                                            <select name="price" id="" className='ms-2'>
                                                <option value="">Week</option>
                                            </select>
                                    </div>
    
                                </div> */}
                                
                                <div className="chartMain">
                                    {/* <ResponsiveContainer width="100%" height="100%">
                                        <LineChart
                                        width={500}
                                        height={300}
                                        data={data}
                                        margin={{
                                            top: 5,
                                            right: 30,
                                            left: 20,
                                            bottom: 5,
                                        }}
                                        >
                                        <CartesianGrid vertical={false} stroke="#ECE9F100" strokeLinecap="3 3" />
                                        <XAxis dataKey="name" />
                                        <YAxis />
                                        <Tooltip />
                                        <Line type="monotone" dataKey="uv" stroke="#05B4EF" />
                                        </LineChart>
                                    </ResponsiveContainer> */}
                                    <div className='chartMainInner'>
                                        <div className='chartDiv'>
                                        <ChartComponent
                                            symbol={(liveData[0]?.pairIdRef?.tokenA + liveData[0]?.pairIdRef?.tokenB).toUpperCase()}
                                            />
                                            {/* <iframe src={liveData[0]?.pairIdRef.graphApi} width="100%" height="536px" scrolling="auto" marginwidth="0" marginheight="0" frameborder="0" border="0" style={{border:"0",margin:"0",padding:"0",lineHeight:"14px"}}></iframe> */}
                                            
                                        </div>
                                        {/* <div style="color: #626B7F; line-height: 14px; font-weight: 400; font-size: 11px; box-sizing: border-box; padding: 2px 6px; width: 100%; font-family: Verdana, Tahoma, Arial, sans-serif;"> */}
                                        {/* <a href="https://coinlib.io" target="_blank" style="font-weight: 500; color: #626B7F; text-decoration:none; font-size:11px">Cryptocurrency Prices</a>&nbsp;by Coinlib</div> */}
                                    </div>
                                </div>
                            </div>
    
                          
                        </div>
                        
                    </div>
                </div>
            </section>
              {loader &&
            <div className="seekoLoader">
                <div className="loader">
                    <img src={TenupIcon} alt="loader" />
                </div>
            </div>
            }
            <AlertModal show={showMsg} handleClose={handleCloseMsg} msg={msgText} checkStatus={checkStatus} />
            
            <Modal show={show} onHide={handleClose} centered>
       
                <Modal.Body>
                    <div className="WalletModal analyzeModal">
                        <h1 className='py-4 m-0'>Analyze</h1>
                        <div className="closeIcon" onClick={handleClose}>
                            <img src={closeIco} alt="" />
                        </div>
                        {selectedSide == "up" ?
                        <div className="sideImg"><img src={goingUp} alt="" /></div>
                        :
                        <div className="sideImg"><img src={goingDown} alt="" /></div>
                        }

                         {/* pay from */}
                         {selectedSide == "up" ?
                            <div className="payMain " >
                                <div className="plotCard">
                                    <div className="title">You Pay</div>
                                    <div className="row1">
                                                    <p className="plot">USDT Balance</p>
                                                    {liveData[0]?.userBalance &&
                                                    <p className="amount">~ ${liveData[0]?.userBalance  % 1  != 0 ? liveData[0]?.userBalance.toFixed(2) : liveData[0]?.userBalance}</p>
                                                    }
                                    </div>
                                    <div className="row2">
                                                    <div className="dropdown">
                                                        <button className="btn dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                        <img src={usdt} alt="" /> USDT
                                                        </button>
                                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                        <li><a className="dropdown-item" href="#">Action</a></li>
                                                        <li><a className="dropdown-item" href="#">Another action</a></li>
                                                        <li><a className="dropdown-item" href="#">Something else here</a></li>
                                                        </ul>
                                                    </div>
                                                    <p>
                                                        <input name='amount' onChange={(e)=>handleForm(e)} value={formData.amount} type="number" min={5} placeholder='0' />
                                                    </p>
                                    </div>
                                    <div>
                                                    <textarea name='analysis' onChange={(e)=>handleForm(e)} value={formData.analysis}  cols="30" rows="3" placeholder='Please share your Analysis'></textarea>
                                    </div>
                                    {/* <p className="text">Or, You can choose a popular amount</p>
                                    <div className="tubBtnDiv">
                                                    <button onClick={(e)=>handleForm(true)} className={formData.prediction?' btn':'btn'} > { formData.prediction && <i className="fas fa-check-circle"></i> }  UP</button>
                                    </div> */}
                                </div>
                                <div className="verticleLine"></div>
                                <div className="youReciveDiv">
                                    <div className="title mb-2">You Recieve</div>
                                    <div className="expectedM">
                                        <p>Expected Reward</p>
                                        <p className='price'>${liveData[0]?.price}</p>
                                        {/* <p className='price'>{liveData[0]?.roiUp * formData.amount}</p> */}
                                    </div>
                                    <div className="expectedM">
                                        <p>Potential ROI</p>
                                        <p className='price'>{liveData[0]?.roiUp}X</p>
                                    </div>
                                            
                                </div>
        
                            </div>
                            :
                            <div className="payMain" >
                                <div className="plotCard">
                                    <div className="title">You Pay</div>
                                    <div className="row1">
                                        <p className="plot">USDT Balance</p>
                                        {liveData[0]?.userBalance &&
                                        <p className="amount">~ ${liveData[0]?.userBalance  % 1  != 0 ? liveData[0]?.userBalance.toFixed(2) : liveData[0]?.userBalance}</p>
                                        }
                                    </div>
                                    <div className="row2">
                                        <div className="dropdown">
                                            <button className="btn dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                            <img src={usdt} alt="" /> USDT
                                            </button>
                                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                            <li><a className="dropdown-item" href="#">Action</a></li>
                                            <li><a className="dropdown-item" href="#">Another action</a></li>
                                            <li><a className="dropdown-item" href="#">Something else here</a></li>
                                            </ul>
                                        </div>
                                        <p>
                                            <input name='amount' onChange={(e)=>handleForm(e)} value={formData.amount} type="number" placeholder='0' />
                                        </p>
                                    </div>
                                    <div>
                                        <textarea name='analysis' onChange={(e)=>handleForm(e)} value={formData.analysis}  cols="30" rows="3" placeholder='Please share your Analysis'></textarea>
                                    </div>
                                    {/* <p className="text">Or, You can choose a popular amount</p> */}
                                    {/* <div className="tubBtnDiv"> */}
                                        {/* <button onClick={(e)=>handleForm(false)} className={formData.prediction == false?'btn':'btn'}>{ !formData.prediction && <i className="fas fa-check-circle"></i> }  DOWN </button> */}
                                        {/* <button onClick={(e)=>handleForm(true)} className={formData.prediction?'bg-dark btn':'btn'} > { formData.prediction && <i className="fas fa-check-circle"></i> }  UP</button> */}
                                        {/* <button className='btn'>30 TUP</button> */}
                                    {/* </div> */}
                                </div>
                                <div className="verticleLine"></div>
                                <div className="youReciveDiv">
                                    <div className="title mb-2">You Recieve</div>
                                    <div className="expectedM">
                                        <p>Expected Reward</p>
                                        <p className='price'>${liveData[0]?.price}</p>
                                        {/* <p className='price'>{(liveData[0]?.roiDown * formData.amount)}</p> */}
                                    </div>
                                    <div className="expectedM">
                                        <p>Potential ROI</p>
                                        <p className='price'>{liveData[0]?.roiDown}x</p>
                                    </div>
                                </div>
                            </div>
                            
                            }
                                <div className='submitBtnDiv'>
                                    <div className="submitBtn" onClick={formSubmit} >Submit</div>
                                </div>

                    
                    </div>
                </Modal.Body>
                
                </Modal>
            </>
        )
    }else {
        return(
            <>
            <div className='userContentMinHeight vertical-center'>
                <h1 className='text-center my-4'>No Data</h1>
            </div>
              {loader &&
            <div className="seekoLoader">
                <div className="loader">
                    <img src={TenupIcon} alt="loader" />
                </div>
            </div>
            }
            </>
        )
    }
}

function Timer({startTime,predictionTime}) {

    var [day,setDay]=useState("")
    var [hours,setHours]=useState("")
    var [minutes,setMinutes]=useState("")
    var [seconds,setSeconds]=useState("")
    var day1;
    var hours1;
    var minutes1;
    var seconds1;
    useEffect(()=>{
      time()
    },[])
  
    function time(){
      
      var timeElement, eventTime, currentTime, duration, interval, intervalId;
      interval = 1000; // 1 second
    
      // get time element
      // timeElement = document.querySelector("time");
      // calculate difference between two times
      // "2022-08-15T12:00:00"
      // let eveTime=parseInt(startTime) + parseInt(predictionTime)
    //   console.log(startTime);
    //   console.log(predictionTime);
      let eveTime1=moment.unix(parseInt(startTime) + parseInt(predictionTime)).format('YYYY-MM-DDTHH:mm:ss')
    //   console.log(eveTime1);
      eventTime = moment(eveTime1);
      // based on time set in user's computer time / OS
      currentTime = moment.tz();
    //   console.log(currentTime);
    //   console.log(eventTime);
      // get duration between two times
      duration = moment.duration(eventTime.diff(currentTime));
    //   console.log(duration);
  
      // loop to countdown every 1 second
      setInterval(function() {
        // get updated duration
        duration = moment.duration(duration - interval, 'milliseconds');
        // console.log(duration);
        
    
        // if duration is >= 0
        if (duration.asSeconds() <= 0) {
          clearInterval(intervalId);
          // hide the countdown element
          // timeElement.classList.add("hidden");
          setDay("00")
          setHours("00")
          setMinutes("00")
          setSeconds("00")
        } else {
          // day1 = duration.days()
          // hours1 = duration.hours()
          // minutes1 =duration.minutes()
          // seconds1=duration.seconds()
          setDay(duration.days())
          setHours(duration.hours())
          setMinutes(duration.minutes())
          setSeconds(duration.seconds())
        }
        // setDay(day1)
        // setHours(hours1)
        // setMinutes(minutes1)
        // setSeconds(seconds1)
  
      }, interval);
      
    }
    return(
        <div className="timeDivMain">
        <div className='timeDiv'>
            <div className="time">
              <h1>{day}</h1>
              <span>DAYS</span>
            </div>
            <div className="time">
              <h1>{hours}</h1>
              <span>HOURS</span>
            </div>
            <div className="time">
              <h1>{minutes}</h1>
              <span>MINUTES</span>
            </div>
            <div className="time">
              <h1>{seconds}</h1>
              <span>SECONDS</span>
            </div>
        </div>
        {/* <div className="msg">{msg}</div> */}
      </div>
    )
}

let tvScriptLoadingPromise;

export default function ChartComponent({symbol}) {
    const onLoadScriptRef = useRef();
    // console.log(symbol);
    
    useEffect(
      () => {
        onLoadScriptRef.current = createWidget;
  
        if (!tvScriptLoadingPromise) {
          tvScriptLoadingPromise = new Promise((resolve) => {
            const script = document.createElement('script');
            script.id = 'tradingview-widget-loading-script';
            script.src = 'https://s3.tradingview.com/tv.js';
            script.type = 'text/javascript';
            script.onload = resolve;
  
            document.head.appendChild(script);
          });
        }
  
        tvScriptLoadingPromise.then(() => onLoadScriptRef.current && onLoadScriptRef.current());
  
        return () => onLoadScriptRef.current = null;
  
        function createWidget() {
          if (document.getElementById('tradingview_dbed6') && 'TradingView' in window) {
            new window.TradingView.widget({
              autosize: true,
              symbol: symbol?`BINANCE:${symbol}`:`BINANCE:BTCUSDT`,
              interval: "D",
              timezone: "Etc/UTC",
              theme: "dark",
              style: "1",
              locale: "en",
              enable_publishing: false,
              allow_symbol_change: true,
              container_id: "tradingview_dbed6"
            });
          }
        }
      },
      [symbol]
    );
  
    return (
      <div className='tradingview-widget-container mb-4'>
        <div id='tradingview_dbed6' />
        <div className="tradingview-widget-copyright">
          {/* <a href="https://www.tradingview.com/" rel="noopener nofollow" target="_blank"><span className="blue-text">Track all markets on TradingView</span></a> */}
        </div>
      </div>
    );
  }