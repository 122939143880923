import React,{useEffect, useState} from 'react';
import { BrowserRouter, BrowserRouter as Router,Route,Switch } from "react-router-dom";
import { useHistory, Redirect, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';

import './App.css'
import './component/global/global.css'
import './component/dashboard/dashboard.css'
import './component/loads/loads.css'
import './component/drivers/drivers.css'

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

// import $ from 'jquery';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/js/bootstrap.min.js';
// import 'jquery/dist/jquery.min.js';
// import 'jquery.easing/jquery.easing';

// import 'popper.js/dist/popper'

// route
import  AppRouter from "./route/route";
import Sidebar from './component/global/sidebar/sidebar';
import Navbar from './component/global/navbar/navbar';
import { HomePage } from './componentUser/home';
import { Detail } from './componentUser/detail';
import { NavbarCustom } from './componentUser/navbarCustom';
import { Footer } from './componentUser/footer';
import { UserRoundHistory } from './componentUser/userRoundHistory';
import { RoundHistory } from './componentUser/roundHistory';


function App() {
  const authedUser = useSelector(s => s.authedUser.authedUser);
  const Location = useLocation();
  // console.log(authedUser);
  // console.log(process.env.REACT_APP_ADMIN);
  // console.log(Location);
  // if(process.env.REACT_APP_ADMIN == authedUser.address) {
  //   console.log('====================================');
  //   console.log("sasas");
  //   console.log('====================================');
  //   history.push("/");
  // }
  
  return (
    <>
          {process.env.REACT_APP_ADMIN.toLowerCase() == authedUser?.address?.toLowerCase() ?
            <Router>
                <div id="wrapper">
                  <Sidebar />
                    <div id="content-wrapper" className="d-flex flex-column">
                      <div id="content">
                        <Navbar />
                        {/* Begin Page Content */}
                        <AppRouter />
                        {/* /.container-fluid */} 
                    </div>
                </div>
              </div>

              {/* Scroll to Top Button*/}
              <a className="scroll-to-top rounded" href="#">
                <i className="fas fa-angle-up" />
              </a>
          </Router>
          :
          <Router>
            <NavbarCustom />
                <Route exact path="/" component={()=><HomePage /> } />
                <Route exact path="/round/:RoundId" component={()=><Detail /> } />
                <Route exact path="/userround" component={()=><UserRoundHistory /> } />
                <Route exact path="/roundhistory" component={()=><RoundHistory /> } />
                {/* {Location.pathname != '/' && Location.pathname != '/detail/' && Location.pathname != '/dashboard' && Location.pathname != '/userround' && 
                <Redirect to="/" />
                } */}
                {/* <Route path="*" exact={true} component={()=><HomePage />} /> */}

            <Footer /> 
          </Router>
          } 

     
    </>
  );
}

export {App};
