import React from 'react';
// import ReactDOM from 'react-dom';
import ReactDOM from "react-dom/client";


import './index.css';
import { BrowserRouter, BrowserRouter as Router,Route,Switch } from "react-router-dom";

import {App} from './App.jsx';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import { createStore } from './redux/configureStore';
import { Provider } from "react-redux";

const store = createStore();

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>

  </React.StrictMode>,
  // document.getElementById('root')
);

reportWebVitals();
