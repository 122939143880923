import { serverPath } from "../config.js";

import * as ActionTypes from './ActionTypes';

export const loginUser = (u) => ({
  type: ActionTypes.LOGGED_IN,
  payload: u
});

export const updateUser = (u) => ({
  type: ActionTypes.UPDATE_USER,
  payload: u
});

export const logoutUser = (u) => ({
  type: ActionTypes.LOGGED_OUT,
});

export const loginUserReq = (address, nonce, signature) => dispatch => {
  return fetch(`${serverPath}/login-2`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      address: address.toLowerCase(),
      nonce: nonce,
      message: signature
    })
  })
    .then(res => {
      // console.log(res);
      return res.json();
    })
    .then(r => {
      if (r.status){
        return dispatch(loginUser(r.data[0]));
      }
      else{
        return r.message;
      }
    });
};