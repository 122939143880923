import { useState } from 'react';
import fbIcon from '../images/userImages/fbIcon.svg';
import twitter from '../images/userImages/twitter.svg';
import linkedin from '../images/userImages/linkedin.svg';
import insta from '../images/userImages/insta.svg';
import youtube from '../images/userImages/youtube.svg';
import telegram from '../images/userImages/telegram.svg';
import reddit from '../images/userImages/reddit.svg';
import logo from '../images/logo.png';
import { AlertModal, PrivacyModal, TermsModal } from './alertModal';
import { Link } from 'react-router-dom';

export const Footer = () => {

  const [subscribeEmail, setSubscribeEmail] = useState(false);
  const [show, setShow] = useState(false);
  const [showTerms, setShowTerms] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleCloseTerms = () => setShowTerms(false);
  const handleShowTerms = () => setShowTerms(true);

  const [showMsg, setShowMsg] = useState(false);
  const [msgText, setMsgText] = useState("");

  const handleCloseMsg = () => setShowMsg(false);
  const handleShowMsg = () => setShowMsg(true);

  const isValidEmail = (email) => {
    let regexEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z]+)*$/;
    return regexEmail.test(email);
  };

  const handleSubmit = () => {
    if(isValidEmail(subscribeEmail)){
      handleShowMsg()
      setMsgText(" Thank you for subscribing")
    }else{
      handleShowMsg()
      setMsgText("Invalid email")
    }
  }
    return(
    //     <div className="container">
    //     <footer>
    //       <div className="innerFooter">
    //         <div className="logoMain">
    //           <a href="index.html">
    //             <img src="./img/logoFooter.png" alt="" />
    //           </a>
    //         </div>
    //         <div className="socialMain">
    //           <a href="https://www.facebook.com/TenUpNation/" target="_blank">
    //             <img src={fbIcon} alt="fbIcon" />
    //           </a>
    //           <a href="https://twitter.com/TenupNation" target="_blank">
    //             <img src={twitter} alt="twitter" />
    //           </a>
    //           <a href="#" target="_blank">
    //             <img src={linkedin} alt="linkedin" />
    //           </a>
    //           <a href="https://www.instagram.com/tenupnation/" target="_blank">
    //             <img src={insta} alt="insta" />
    //           </a>
    //           <a href="https://www.youtube.com/channel/UC2FsNw9p7c8HDNdjRLknuiA?view_as=subscriber" target="_blank">
    //             <img src={youtube} alt="youtube" />
    //           </a>
    //           <a href="https://t.me/tenupnation" target="_blank">
    //             <img src={telegram} alt="telegram" />
    //           </a>
    //           <a href="https://www.reddit.com/r/TenUpCoin" target="_blank">
    //             <img src={reddit} alt="reddit" />
    //           </a>
    //         </div>
    //       </div>
    //       <div className="miniFooter">
    //         <div className="copyRight">
    //           Tenup Nation, 2023 All Rights Reserved
    //         </div>
    //         <div className="privacyMain">
    //           <span className='mr-3' onClick={handleShow}>Privacy Policy</span>
    //           <span onClick={handleShowTerms}>Terms & Condition</span>
    //         </div>
    //       </div>
    //     </footer>
    //     <PrivacyModal show={show} handleClose={handleClose} />
    //     <TermsModal show={showTerms} handleClose={handleCloseTerms} />
    // </div>
    <div className="container-fluid">
        <footer>
          <div className="r1">
            <div className="innerFooter">
              <div className="logoMain">
                <Link to="/">
                  <img src={logo} alt="" />
                </Link>
              </div>
            </div>
            <div className="row footer2Main">
              <div className="col-xl-2 col-lg-3 col-sm-4 col-6">
                <h4 className="quickLinks">Links</h4>
                <ul>
                  <li><Link to="/"> Markets </Link></li>
                  <li><Link to="userround"> User History </Link></li>
                  <li><a href="https://tenup.io/" target="_blank"> TenUp </a></li>
                  <li><a href="https://dapp.tenup.io/virtual-mining" target="_blank"> Staking </a></li>
                  <li><a href="https://blog.tenup.io/" target="_blank"> Blog </a></li>
                </ul>
              </div>
              <div className="col-xl-2 col-lg-3 col-sm-4 col-6">
                <h4 className="quickLinks">Our Product</h4>
                <ul>     
                  <li><a href="https://dapp.tenup.io/" target="_blank"> Tenup DAPP </a></li>
                  <li><a href="https://dapp.tenup.io/virtual-mining" target="_blank"> Virtual Staking </a></li>
                  <li><a href="https://bridge.tenup.io/" target="_blank"> Cross Chain Bridge </a></li>
                  <li><a href="https://ludolove.tenup.io/" target="_blank"> Ludo love </a></li>
                  <li><a href="https://topup.tenup.io/" target="_blank"> Tenup Top Up </a></li>
                </ul>
              </div>
              <div className="col-xl-2 col-lg-2 col-sm-4">
                <h4 className="quickLinks">Company</h4> 
                <ul>     
                  {/* <li><a href="#"> Careers </a></li>
                  <li><a href="#"> Press </a></li> */}
                  <li><a onClick={handleShowTerms}> Terms & conditions </a></li>
                  <li><a onClick={handleShow}> Privacy Policy </a></li>
                </ul>
              </div>
              <div className="col-xl-6 col-lg-4 ">
                <h4 className="quickLinks mb-4">Subscribe To Tenup Analyzer</h4>
                <div className="subsMain">
                  <input type="text" onChange={(e)=>setSubscribeEmail(e.target.value)} />
                  <button placeholder='Enter your email' type="submit" onClick={handleSubmit}>Subscribe</button>
                </div>
                <p className="desc mb-4">By submitting this form, you agree to receive marketing and  other communications from Tenup about Tenup products. You can unsubscribe from these  communications at any time. For more information on our  privacy practices, please review our Privacy Policy.</p>
                <div className="socialMain">
                  <a href="https://www.facebook.com/TenUpNation/" target="_blank">
                    <img src={fbIcon} alt="" />
                  </a>
                  <a href="https://twitter.com/TenupNation" target="_blank">
                    <img src={twitter} alt="" />
                  </a>
                  {/* <a href="#" target="_blank">
                    <img src={linkedin} alt="" />
                  </a> */}
                  <a href="https://www.instagram.com/tenupnation/" target="_blank">
                    <img src={insta} alt="" />
                  </a>
                  <a href="https://www.youtube.com/channel/UC2FsNw9p7c8HDNdjRLknuiA?view_as=subscriber" target="_blank">
                    <img src={youtube} alt="" />
                  </a>
                  <a href="https://t.me/tenupnation" target="_blank">
                    <img src={telegram} alt="" />
                  </a>
                  {/* <a href="https://www.reddit.com/r/TenUpCoin" target="_blank">
                    <img src={reddit} alt="" />
                  </a> */}
                  
                </div>
              </div>
            </div>
          </div>
          <div className="copyRight">
            Tenup Nation, 2024 All Rights Reserved
          </div>
        </footer>
        <PrivacyModal show={show} handleClose={handleClose} />
        <TermsModal show={showTerms} handleClose={handleCloseTerms} />
        <AlertModal show={showMsg} handleClose={handleCloseMsg} msg={msgText} />

      </div>
    )
}