import React, { useState,useEffect,useRef, useMemo } from 'react';
import {Link} from 'react-router-dom'   
import axios from "axios";
import { useDispatch, useSelector } from 'react-redux';
import moment from "moment-timezone";
import web3 from "../web3"
import {serverPath} from "../config"
import {tenupPredictionContract, PredictionAbi} from "../contractAbi"

import ethereum from '../images/userImages/ethereum.svg';
import bitcoin from '../images/userImages/bitcoin.svg';
import ethTableLogo from '../images/userImages/ethTableLogo.svg';
import chooseMarket from '../images/userImages/chooseMarket.png';
import goingUp from "../images/userImages/goingUp.svg"
import goingDown from "../images/userImages/goingDown.svg"
import usdtIco from "../images/userImages/usdt.png"
import { Loader } from './loader';
import { AlertModal } from './alertModal';
import Pagination from './pagination/pagination';

let PageSize = 10;

export const UserRoundHistory = () => {
    const authedUser = useSelector(s => s.authedUser.authedUser);
    const [roundData, setRoundData] = useState([]);
    const [loader,setLoader]=useState(false)
    
    const [showMsg, setShowMsg] = useState(false);
    const [msgText, setMsgText] = useState("");
    const handleCloseMsg = () => setShowMsg(false);
    const handleShowMsg = () => setShowMsg(true);
    const [currentPage, setCurrentPage] = useState(1);

 
    async function getRoundData() {
        await axios.get(`${serverPath}/user-round-data`)
        .then((res)=>{
            // console.log(res.data);
            let currentUserData = res.data.filter( e=> e.userId.address == authedUser.address)
 
          // currentTime > row.startTime && currentTime < row.resultTime
        //   var live=res.data.filter(e=>currentTime > e.startTime && currentTime < (parseInt(e.startTime) + parseInt(e.predictionTime) ) )
        //   console.log(live);
          setRoundData(currentUserData)

        })
        .catch((res)=>{
          console.log(res);
        })
      }

      useEffect(()=>{
        if(authedUser.address){
            getRoundData()
        }
      },[])

      const handleClaim = async (roundId,predId) =>{
        var PredContract = new web3.web3.eth.Contract(PredictionAbi, tenupPredictionContract );
        // console.log(PredContract);
        // console.log(roundId);

        try{
            setLoader(true)
              var result= await PredContract.methods.claimWinner(parseInt(roundId)).send({from:authedUser.address})
              // console.log(result);
              if(result.status){
                
                  updateToDb(predId)
              }else{
                  // alert("transaction not completed")
                  handleShowMsg()
                  setMsgText("transaction not completed")
                  setLoader(false)
              }
          }catch{
            console.log("something went wrong");
            setLoader(false)
          }
      }

      const updateToDb = async (predId) => {
        let sendData = {
            predId:predId
        }
        await axios.put(`${serverPath}/prediction`,sendData,{
          headers: {
            'Content-Type': 'application/json',
        }
        })
        .then((res)=>{
          // console.log(res);
          getRoundData()
              setLoader(false)
            })
            .catch((res)=>{
              console.log(res);
              setLoader(false)
        })
      }

      const currentTableData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return roundData.slice(firstPageIndex, lastPageIndex);
      }, [currentPage,roundData]);



    if(authedUser.address){
        return(
            <div className="homePage">
                <section className='crptoMarket userContentMinHeight'>
                <div className="container">
                    <h1>User Round History</h1>
                    {/* <div className="filterRow">
                    <div className="col1">
                        <p className='head'>Filter by Asset:</p>
                        <select name="" id="" className='select'>
                        <option value="">All</option>
                        </select>
                    </div>
                    <div className="col2">
                        <p className='head'>Filter by Asset:</p>
                        <div className="linkMain">
                        <button className='btn fLink p-0'>All</button>
                        <button className='btn fLink p-0'>Rapid</button>
                        <button className='btn fLink p-0'>1-Hour</button>
                        <button className='btn fLink p-0'>Daily</button>
                        <button className='btn fLink p-0'>Weekly</button>
                        </div>
                    </div>
                    </div> */}
                    <div className="marketTable">
                    <div className='table-responsive'>
                        <table className="table table-striped">
                            <thead>
                            <tr>
                                <th scope="col"><div className='w-mc m-auto'> Market </div></th>
                                <th scope="col"><div className='w-mc m-auto'> Type </div></th>
                                <th scope="col"><div className='w-mc m-auto'> Round Id </div></th>
                                <th scope="col"><div className='w-mc m-auto'> Invested Amount </div></th>
                                <th scope="col"><div className='w-mc m-auto'> Analyzed </div></th>
                                <th scope="col"><div className='w-mc m-auto'> Status </div></th>
                                <th scope="col" className='text-center'>Action</th>
                            </tr>
                            </thead>
                            <tbody>
                        {
                            currentTableData.map((val,key)=>(
                            <tr>
                                <td><div className='text-uppercase w-mc m-auto'><img src={val?.RoundId?.pairIdRef?.tokenImage} className="cIcon" alt="eth" /> {val?.RoundId?.pairIdRef?.pairName}</div></td>
                                <td><div className='text-uppercase font-weight-normal w-mc m-auto'>{val?.RoundId?.type}</div></td>
                                <td><div className='w-mc m-auto'> 
                                  <Link to={`/round/${val?.RoundId?.roundID}`} className='td-none'>
                                    {val?.RoundId?.roundID}
                                  </Link>
                                </div></td>
                                <td><div className='w-mc m-auto font-weight-normal'><img src={usdtIco} className="cIcon2" alt="eth" /> {val?.amount}</div></td>
                                <td><div className='w-mc m-auto'>
                                    {val?.prediction?
                                    <img className='w-30' src={goingUp} alt="" />
                                    :
                                    <img className='w-30' src={goingDown} alt="" />}
                                  </div>
                                </td>
                                <td><div className='text-uppercase w-mc m-auto font-weight-normal'>
                                    {val?.RoundId?.status == "ended"?
                                    <>
                                    {val?.RoundId?.winResult == val?.prediction  ?"Win":"Lose"} 
                                    </>
                                    :"pending" }
                                </div></td>
                              <td><div className='w-mc m-auto'> 
                              {val?.RoundId?.status == "ended" && val.status == "invest" && val?.RoundId?.winResult == val?.prediction ?
                                <button  className='pridictBtn' onClick={()=>handleClaim(val?.RoundId?.roundID,val?._id)}>Claim Now</button> 
                                :val.status == "invest" && val?.RoundId?.status != "ended"?
                                <span className='minW112'>-</span>
                                :val?.RoundId?.winResult == val?.prediction ?
                                <button className='pridictBtn' disabled style={{opacity:"0.7"}}>Claimed</button>
                                :
                                <span className='minW112'>-</span>
                              }
                              </div></td>
                            </tr>
                            ))
                        }
                        
        
                            
                            </tbody>
                        </table>
                    </div>
                    </div>
                    <Pagination
                        className="pagination-bar"
                        currentPage={currentPage}
                        totalCount={roundData.length}
                        pageSize={PageSize}
                        onPageChange={page => setCurrentPage(page)}
                      />
                </div>
                </section>
                <Loader loader={loader} />
                <AlertModal show={showMsg} handleClose={handleCloseMsg} msg={msgText} />
            </div>
        )
    }else{
        return(
            <>
            <div className='userContentMinHeight'>
            <h1 className='text-center my-4'>Please Connect Wallet</h1>
            </div>
            </>
        )
    }

} 