
import TenupIcon from "../images/Tenup-Icon.png"

export function Loader({loader}) {
    return(
        <>
            {loader &&
                <div className="seekoLoader">
                    <div className="loader">
                        <img src={TenupIcon} alt="loader" />
                    </div>
                </div>
                }
        </>
    )
}