import profile from "../../images/profile.jpg";

const driverData=[
    {
        profile:profile,
        driverName:"John Doe",
        driverEmail:"Johndoe@gmail.com",
        companyName:"ABC Trucking, LLC",
        loadMove:"37",
        status:"ongoing",
    },
    {
        profile:profile,
        driverName:"John Doe",
        driverEmail:"Johndoe@gmail.com",
        companyName:"ABC Trucking, LLC",
        loadMove:"37",
        status:"ended",
    },
    {
        profile:profile,
        driverName:"John Doe",
        driverEmail:"Johndoe@gmail.com",
        companyName:"ABC Trucking, LLC",
        loadMove:"37",
        status:"ended",
    },
    {
        profile:profile,
        driverName:"John Doe",
        driverEmail:"Johndoe@gmail.com",
        companyName:"ABC Trucking, LLC",
        loadMove:"37",
        status:"ongoing",
    },
    {
        profile:profile,
        driverName:"John Doe",
        driverEmail:"Johndoe@gmail.com",
        companyName:"ABC Trucking, LLC",
        loadMove:"37",
        status:"ended",
    },
    {
        profile:profile,
        driverName:"John Doe",
        driverEmail:"Johndoe@gmail.com",
        companyName:"ABC Trucking, LLC",
        loadMove:"37",
        status:"ended",
    },
    {
        profile:profile,
        driverName:"John Doe",
        driverEmail:"Johndoe@gmail.com",
        companyName:"ABC Trucking, LLC",
        loadMove:"37",
        status:"ongoing",
    },
    {
        profile:profile,
        driverName:"John Doe",
        driverEmail:"Johndoe@gmail.com",
        companyName:"ABC Trucking, LLC",
        loadMove:"37",
        status:"ended",
    },
    {
        profile:profile,
        driverName:"John Doe",
        driverEmail:"Johndoe@gmail.com",
        companyName:"ABC Trucking, LLC",
        loadMove:"37",
        status:"ended",
    },
]
export {driverData}