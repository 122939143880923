import React,{useEffect,useState} from 'react';
import axios from "axios";

import web3 from "../../web3"
import Web3 from "web3"
import {serverPath} from "../../config"
import { useDispatch, useSelector } from 'react-redux';
import {tenupPredictionContract,PredictionAbi} from "../../contractAbi"

import rightArrow from "../../images/right-arrow.svg";
import dropdownicon from "../../images/dropdown-icon.svg";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import TenupIcon from "../../images/Tenup-Icon.png"

// import {PredContract} from "../../Contract"




function Loads() {
  const [show, setShow] = useState(false);
  const [pair, setPair] = useState({
    currency1:"",
    currency2:"",
    tokenImage:"",
    fetchApi:"",
    graphApi:"",
  });
  const [pairData, setPairData] = useState([]);
  const [loader,setLoader]=useState(false)

  const authedUser = useSelector(s => s.authedUser.authedUser.address);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleChange = (e) => {
    setPair({...pair,[e.target.name]:e.target.value})
  };


  async function submitPair() {
    if(authedUser.length > 0){
     var PredContract = new web3.web3.eth.Contract(PredictionAbi, tenupPredictionContract );

     if(pair.currency1 != "" && pair.currency2 != "" && pair.graphApi != "" && pair.tokenImage != "" ){
       //  var result = await PredContract.methods.getPair(authedUser).call()

       let mergeP =pair.currency1 +'/'+ pair.currency2 
       let pairBytes32 =web3.web3.utils.fromAscii(mergeP)
      //  console.log(mergeP);
      //  console.log(PredContract);
      //  console.log(pairBytes32);
       try{
        setLoader(true)

         var result= await PredContract.methods.addPair(pairBytes32).send({from:authedUser})
        //  console.log(result);
            if(result.status){
              saveToDb(result.events.NewPair.returnValues.pairID,mergeP,pair.currency1,pair.currency2, pair.tokenImage,pair.fetchApi,pair.graphApi)
            }else{
              alert("transaction not completed")
              setLoader(false)

            }
       }catch(err){
        console.log("something went wrong");
        console.log(err);
              setLoader(false)

       }

     }else {
       alert("please fill all field")
     }
  
      // console.log(result);
    }else {
      alert("please connect wallet")
    }

  }

  async function saveToDb(pairId,pairName,tokenA,tokenB,tokenImage,fetchApi,graphApi) {
    // console.log(pairId);
    // console.log(pairName);
    // console.log(tokenA);
    // console.log(tokenB);
    // console.log(tokenImage);
    // console.log(fetchApi);
    let sendData={
      pairId:pairId,
      pairName:pairName,
      tokenA:tokenA,
      tokenB:tokenB,
      tokenImage:tokenImage,
      fetchApi:fetchApi,
      graphApi:graphApi,
    }

    await axios.post(`${serverPath}/add-pair`,sendData,{
      headers: {
        'Content-Type': 'application/json',
    }
    })
    .then((res)=>{
      // console.log(res);
      if(res.data.data._id){
        handleClose()
        getPairData()
        setLoader(false)

      }
    })
    .catch((res)=>{
      console.log(res);
    })

    }

    async function getPairData() {
      await axios.get(`${serverPath}/pair-data`)
      .then((res)=>{
        // console.log(res.data);
        setPairData(res.data)
      })
      .catch((res)=>{
        console.log(res);
      })
    }

    useEffect(()=>{
      getPairData()
    },[])

    return(
      <>
                  {/* Begin Page Content */}
                  <div className="container-fluid">
                     {/* Content Row */}
                      <div className="row">
                      {/* Area Chart */}
                      <div className="col-xl-12 mb-4">
                        <div className="card sortby-box br-10 border-transparent">
                            {/* Card Header - Dropdown */}
                            <div className="card-header br-10  bg-white border-transparent py-3 d-flex flex-row align-items-center justify-content-between">
                            <div className="d-flex ">
                                <h6 className="my-auto chart-heading color-light mr-3">Sort by:</h6>
                                <div class="dropdown">
                                    <button class="btn dropdown-toggle border1-light w-190 py-1 px-3 d-flex justify-content-between" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <span className="chart-heading color-light ">Start Date</span>
                                        <img src={dropdownicon} class="w-9 ml-1 mt-1" alt="dropdown icon"></img>
                                    </button>
                                    <div class="dropdown-menu zi-1" aria-labelledby="dropdownMenu2">
                                        <button class="dropdown-item" type="button">Action</button>
                                        <button class="dropdown-item" type="button">Another action</button>
                                        <button class="dropdown-item" type="button">Something else here</button>
                                    </div>
                                </div>

                            </div>
                            <button href="" onClick={handleShow} className=" btn border-transparent loadBtnColor loadBtnBg font-weight-bold fs-12">
                              
                              <span className="fs-14">+</span> Add New Pair
                            </button>
                          </div>
                       
                        </div>
                         {/* table */}

                         <Table pairData={pairData} />
                      </div>
                      
                      
                     
                    </div>
              
                  </div>
                  {/* /.container-fluid */}

        <Modal show={show} onHide={handleClose}>
        
          <Modal.Body>
            <div className="pairModal">
                <div className="row1">
                  <h2>Add Pair</h2>
                  <div className="close" onClick={handleClose} ><i class="fas fa-times-circle"></i></div>
                </div>
                <div>
                  <input type="text" name="currency1"  value={pair.currency1} onChange={(e)=>handleChange(e)} placeholder="Enter Pair Name" className="form-control theme-bg-color border-0 small mb-2" />
                  <input type="text" name="currency2"  value={pair.currency2} onChange={(e)=>handleChange(e)} placeholder="Enter Pair Name" className="form-control theme-bg-color border-0 small mb-2" />
                  <input type="text" name="tokenImage" value={pair.tokenImage} onChange={(e)=>handleChange(e)} placeholder="Token Image Link" className="form-control theme-bg-color border-0 small mb-2" />
                  <input type="text" name="fetchApi"   value={pair.fetchApi} onChange={(e)=>handleChange(e)} placeholder="Fetch Api" className="form-control theme-bg-color border-0 small" />
                  <input type="text" name="graphApi"   value={pair.graphApi} onChange={(e)=>handleChange(e)} placeholder="Graph Api" className="form-control theme-bg-color border-0 small" />
                </div>
                <div className="btn submitBtn" onClick={submitPair}>Submit</div>
            </div>
          </Modal.Body>
       
        </Modal>
        {loader &&
            <div className="seekoLoader">
                <div className="loader">
                    <img src={TenupIcon} alt="loader" />
                </div>
            </div>
        }
         
      </>
    )
  }

  const Table=({pairData})=>{
 
    return(
      <>
        <div className="card sortby-box br-10 border-transparent mt-3"> 
          <div className="card-header br-10 table-responsive  bg-white border-transparent py-3 d-flex flex-row align-items-center justify-content-between">
             <table class="table table-hover">
                                  <thead>
                                    <tr>
                                      <th scope="col"></th>
                                      <th scope="col">Pair Id</th>
                                      <th scope="col">Pair Name</th>
                                      <th scope="col">Created At</th>
                                    
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {pairData.map((val,key)=>(
                                    <tr key={key}>
                                      <td scope="row">
                                        <div>{val.pairId}</div>
                                      </td>
                                      <td><img src={rightArrow} className="w-12" alt="" /></td>
                                      
                                      <td>
                                        <div className='logoDivMain'>
                                          <div className='logoDiv'>
                                            <img src={val.tokenImage} alt="logo" />
                                          </div>
                                          {val.pairName}
                                        </div>
                                      </td>
                                      <td>
                                        <div>{val.createdAt}</div>
                                      </td>
                                      
                                    </tr>
                                    ))}
                                
                                  
                                  </tbody>
             </table>
          </div>
        </div>

   
      </>
    )
  }
  const LoadSecondRow=()=>{
    return(
      <div className="col-xl-4 col-lg-4 mb-3">
      <div className="card h-100 mb-4 br-10 border-transparent overflow-hidden">
        {/* Card Header - Dropdown */}
        <div className="card-header border-bootom-light bg-white  py-3 d-flex flex-row align-items-center justify-content-between">
          <div>
            <div className="color-dark fs-19 font-weight-bold">$1,850</div>
            <div className="color-light font-weight-bold fs-12">$2.78/mi</div>
          </div>
          <div>
            <div className="color-dark fs-19 font-weight-bold">664mi</div>
            <div className="color-light font-weight-bold fs-12">Mon 4/26</div>
          </div>
        </div>

        {/* Card header */}
        <div className="card-header border-bootom-light bg-white  py-3 ">
          {/* driver card row */}
          <div className="row py-2  px-0">
            <div className="col-auto">
                <div className="circle-light"></div>
                <div class="vertical-line-light m-auto"></div>
                <div className="circle"></div>
                <div class="vertical-line-dark m-auto"></div>
                <div className="circle-fill"></div>

            </div>
            <div className="col ">
              <p className="color-light fs-13 font-weight-600 mb-0">Deadhead</p>
              <div className="d-flex">
                <hr  className="w-75 ml-0 border-top-light" />
                <p className="deadhead-mi m-auto">43 mi</p>
              </div>
              <div className="deadhead-title">Miami, FL</div>
              <div className="deadhead-time">Mon 4/26, 9:30 EST</div>
              <div className="d-flex">
                <hr  className="w-75 ml-0 border-top-light" />
                <p className="deadhead-mi m-auto">664 mi</p>
              </div>
              <div className="deadhead-title">Atlanta, GA</div>
              <div className="deadhead-time">Tue 4/27, 17:30 - 19:30 EST</div>

            </div>
          </div>
        </div>

        <div className="card-header bg-white border-transparent pb-2 pt-3 d-flex flex-row align-items-center justify-content-between">
              <div>
                  <div className="color-light font-weight-600 fs-10">Trailer Type</div>
                  <div className="color-dark fs-17 font-weight-bold">Reefer</div>
              </div>
              <div>
                  <div className="color-light font-weight-600 fs-10">Trailer Length</div>
                  <div className="color-dark fs-17 font-weight-bold">53'</div>
              </div>
        </div>
        <div className="card-header bg-white border-transparent py-2 d-flex flex-row align-items-center justify-content-between">
              <div>
                  <div className="color-light font-weight-600 fs-10">Full or partial</div>
                  <div className="color-dark fs-17 font-weight-bold">Full</div>
              </div>
              <div>
                  <div className="color-light font-weight-600 fs-10">WEIGHT</div>
                  <div className="color-dark fs-17 font-weight-bold">35,000 lbs</div>
              </div>
        </div>
        <div className="card-header border-bootom-light bg-white border-transparent pt-2 pb-3">
              <div>
                  <div className="color-light font-weight-600 fs-10">Requirement</div>
                  <div className="color-dark fs-17 font-weight-bold">CARB, Temp control to 28 F</div>
              </div>
        </div>
        <div className="card-header bg-white border-transparent pt-3 pb-2">
              <div>
                  <div className="color-light font-weight-600 fs-10">Commodity</div>
                  <div className="color-dark fs-17 font-weight-bold">Refrigerated Produce</div>
              </div>
        </div>
        <div className="card-header border-bootom-light bg-white pt-2 pb-3">
              <div>
                  <div className="color-light font-weight-600 fs-10 mb-1">Comment</div>
                  <div className="color-light font-weight-600 fs-11">Driver will be required to wear safety vest while at the shippers facility</div>
              </div>
        </div>
        {/* bid and post button */}
        <div className="card-header bg-white border-transparent py-3 d-flex flex-row align-items-center justify-content-between">
        <a href="" class=" btn border-transparent loadBtnColor-underline font-weight-bold fs-12 w-100">Bid</a>
        <a href="" class=" btn border-transparent loadBtnColor loadBtnBg font-weight-bold fs-12 w-100 ml-2"><span class="fs-14">+</span> Add New Load</a>
        </div>
      

      </div>
    </div>
    )
  }
  export default Loads