import React, { useState,useEffect } from "react";
import {Button, Modal} from 'react-bootstrap';
import {NavLink, useHistory} from 'react-router-dom';
import {Link} from 'react-router-dom'
// import WalletConnectProvider from "@walletconnect/web3-provider";
import { EthereumProvider } from '@walletconnect/ethereum-provider'

import { bscId, serverPath } from "../config.js";
import Web3 from "web3";
import web3 from '../web3';
import { loginUser, loginUserReq, logoutUser } from "../redux/ActionCreators";
import { useDispatch, useSelector } from 'react-redux';



import logo from "../images/userImages/logo.png"
import help from "../images/userImages/help.png"
import metamask from "../images/userImages/metamask.png"
import walletConnect from "../images/userImages/walletConnect.png"
import bitkeepLogo from "../images/bitkeepLogo.svg"
import { AlertModal } from "./alertModal.js";


export const NavbarCustom = ({}) => {
  const dispatch = useDispatch();
  // const authedUser = useSelector(s => s.authedUser);
  const authedUser = useSelector(s => s.authedUser.authedUser);
  const navigate = useHistory()


  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showMsg, setShowMsg] = useState(false);
  const [msgText, setMsgText] = useState("");
  const handleCloseMsg = () => setShowMsg(false);
  const handleShowMsg = () => setShowMsg(true);

  const [isConnected,setisConnected] = useState(false);
  const [activeAccount,setActiveAccount] = useState(null);
  const [activeBalance,setBalance] = useState(null);
  const [ID,setId] = useState(null);

  const checkIfUserExists = (address) => {console.log(address) ; return fetch(`${serverPath}/users/exists/${address}`).then(r => r.json())}

  const jwtValid = (token, address) => {
    return fetch(`${serverPath}/users/check-jwt/${address}`, {
      method: 'GET',
      headers: {
        'Authorization' : `Bearer ${token}`
      }
    }).then(r => {
      // console.log(r);
      if (r.ok){
        return r.json();
      }
      else{
        return {success: false};
      }
    });
  }
  
  const verifySignature = (signature, address) => {
    // console.log(signature);
    // console.log(address);
    return fetch(`${serverPath}/users/login`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        signature,
        address
      })
    }).then(r => r.json())
  }

  const logingHandler = async ()=>{
    web3.web3 = new Web3(window.ethereum);
    console.log(await web3.web3.eth.getChainId());
    console.log(parseInt(window.ethereum.chainId));
    
    if (!window.ethereum){
      // alert('Install Metamask');
      handleShowMsg()
      setMsgText("Install Metamask")
    }
    else if (parseInt(window.ethereum.chainId) !== parseInt(bscId)){
      
      // console.log(parseInt(window.ethereum.chainId));
      // alert('Please Connect to Binance Mainnet');
      handleShowMsg()
      setMsgText("Please Switch to Binance Mainnet")
    }
    else{
      web3.web3 = new Web3(window.ethereum);
      let accs = await window.ethereum.request({
        method: 'eth_requestAccounts'
      });

      if (accs.length > 0){
        let ad = accs[0].toLowerCase();
        let noToken = true;
        // console.log(accs);
        let r = await checkIfUserExists(ad);
        // console.log(r);
        if (localStorage.getItem(ad.toLowerCase())){
          let valid = await jwtValid(localStorage.getItem(ad.toLowerCase()), ad.toLowerCase());
          // console.log(valid);
          if (valid.success){
            valid.user.token = localStorage.getItem(ad.toLowerCase());
            dispatch(loginUser(valid.user));
            noToken = false;
            let b = await web3.web3.eth.getBalance(accs[0]);
            // setBalance(balance)
            setActiveAccount(accs[0]);
            setisConnected(true)
            setShow(false)
            setBalance(b);
            setId((parseInt(window.ethereum.chainId)).toString());
          }
          else{
            localStorage.removeItem(ad);
          }
        }
        
        if (noToken){
          let signature, signed = true;
          try{
            signature = await web3.web3.eth.personal.sign(
              `I am signing my one-time nonce: ${r.user.nonce}`,
              accs[0],
              ''
            );  
          }
          catch(e){
            console.log(e);
            signed = false;
            // alert('Need to sign signature to continue.');
            handleShowMsg()
            setMsgText("Need to sign signature to continue.")
          }
          if (signed){
            // console.log(signature);
            // console.log(ad);
            let res = await verifySignature(signature, ad);
            // console.log(res);
            if (res.success){
              r.user.token = res.token;
              dispatch(loginUser(r.user));
              setActiveAccount(accs[0]);
              let b = await web3.web3.eth.getBalance(accs[0]);
              setisConnected(true)
              setShow(false)
              setBalance(b);
              setId((parseInt(window.ethereum.chainId)).toString());
              // getContract(web3.web3,accs[0],b,(parseInt(window.ethereum.chainId)).toString())  
            }
            else{
              // alert('Invalid Signature');
              handleShowMsg()
              setMsgText("Invalid Signature")
            }
          } 
        }
      }
    }
    // setisConnecting(false)
  }

    // mobile wllet provider
    // const providerOptions = new WalletConnectProvider({
    //   rpc: {
    //     56: "https://bsc-dataseed1.defibit.io/",
    //     97: "https://data-seed-prebsc-1-s1.binance.org:8545",
    //     1: "https://mainnet.infura.io/v3/3f2f5e20071a4cbe9dd30a0090ff6402",
    //     qrcode: true,
    //     pollingInterval: 12000,
    //   },
    //   chainId: 97
    // });
    
    
    const handleWalletConnect=async ()=>{
      try {
        
        const providerOptions = await EthereumProvider.init({
          projectId: process.env.REACT_APP_WC_PROJECT_ID, // REQUIRED your projectId
          chains: [parseInt(process.env.REACT_APP_CHAIN_ID)], // REQUIRED chain ids
          showQrModal: true, // REQUIRED set to "true" to use @walletconnect/modal,
        });
        handleClose()
        var connected = await providerOptions.enable()
        // console.log(connected);
  
          web3.web3 = new Web3(providerOptions);
        // web3.web3 = web3wwwwww;
  
        // console.log(parseInt(window.ethereum.chainId));
        if (await web3.web3.eth.getChainId() !== parseInt(bscId)){
          // alert('Connect to ETH or BSC');
          handleShowMsg()
          setMsgText("Connect to ETH or BSC")
        }
        else{
          let accs = await web3.web3.eth.getAccounts();
          if (accs.length > 0){
            let ad = accs[0].toLowerCase();
            let noToken = true;
            let r = await checkIfUserExists(ad);
            if (localStorage.getItem(ad.toLowerCase())){
              // console.log("----------getitem");
              let valid = await jwtValid(localStorage.getItem(ad.toLowerCase()), ad.toLowerCase());
              // console.log(valid);
              if (valid.success){
                valid.user.token = localStorage.getItem(ad.toLowerCase());
                dispatch(loginUser(valid.user));
                noToken = false;
                let b = await web3.web3.eth.getBalance(accs[0]);
                // setBalance(balance)
                setActiveAccount(accs[0]);
                setisConnected(true)
                setShow(false)
                setBalance(b);
                setId((parseInt(await web3.web3.eth.getChainId())).toString());
              }
              else{
                localStorage.removeItem(ad);
              }
            }
            
            if (noToken){
              let signature, signed = true;
              try{
                signature = await web3.web3.eth.personal.sign(
                  `I am signing my one-time nonce: ${r.user.nonce}`,
                  accs[0],
                  ''
                  );  
              }
              catch(e){
                console.log(e);
                signed = false;
                // alert('Need to sign signature to continue.');
                handleShowMsg()
                setMsgText("Need to sign signature to continue.")
              }
              if (signed){
           
                let res = await verifySignature(signature, ad);
                if (res.success){
                  r.user.token = res.token;
                  dispatch(loginUser(r.user));
                  setActiveAccount(accs[0]);
                  let b = await web3.web3.eth.getBalance(accs[0]);
                  setisConnected(true)
                  setShow(false)
                  setBalance(b);
                  setId((parseInt(await web3.web3.eth.getChainId())).toString());
                  // getContract(web3.web3,accs[0],b,(parseInt(window.ethereum.chainId)).toString())  
                }
                else{
                  // alert('Invalid Signature');
                  handleShowMsg()
                  setMsgText("Invalid Signature")
                }
              } 
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
      

    

  }

  async function bitKeepHandler() {
    const provider = window.bitkeep && window.bitkeep.ethereum;
    // console.log('====================================');
    // console.log(parseInt(provider.chainId));
    // console.log('====================================');

    // console.log(await web3.web3.eth.getChainId());
    
    if (!provider){
      // alert('Install Bitkeep');
      handleShowMsg()
      setMsgText("Install Bitkeep")
    }
    else{
      web3.web3 = new Web3(provider);
      let accs = await provider.request({
        method: 'eth_requestAccounts'
      });
      var chainID=await web3.web3.eth.getChainId()
      // console.log('====================================');
      // console.log(accs);
      // console.log('====================================');
      if(accs.length > 0 ){
        if(parseInt(provider.chainId) !== parseInt(bscId)){
          // alert('Connect to ETH or BSC');
          handleShowMsg()
          setMsgText("Connect to ETH or BSC")
        }else{
          let ad = accs[0].toLowerCase();
          let noToken = true;
          // console.log(accs);
          let r = await checkIfUserExists(ad);
          // console.log(r);
          if (localStorage.getItem(ad.toLowerCase())){
            let valid = await jwtValid(localStorage.getItem(ad.toLowerCase()), ad.toLowerCase());
            // console.log(valid);
            if (valid.success){
              valid.user.token = localStorage.getItem(ad.toLowerCase());
              dispatch(loginUser(valid.user));
              noToken = false;
              let b = await web3.web3.eth.getBalance(accs[0]);
              // setBalance(balance)
              setActiveAccount(accs[0]);
              setisConnected(true)
              setShow(false)
              setBalance(b);
              setId((parseInt(window.ethereum.chainId)).toString());
            }
            else{
              localStorage.removeItem(ad);
            }
          }
          
          if (noToken){
            let signature, signed = true;
            try{
              signature = await web3.web3.eth.personal.sign(
                `I am signing my one-time nonce: ${r.user.nonce}`,
                accs[0],
                ''
              );  
            }
            catch(e){
              console.log(e);
              signed = false;
              // alert('Need to sign signature to continue.');
              handleShowMsg()
              setMsgText("Need to sign signature to continue.")
            }
            if (signed){
              // console.log(signature);
              // console.log(ad);
              let res = await verifySignature(signature, ad);
              // console.log(res);
              if (res.success){
                r.user.token = res.token;
                dispatch(loginUser(r.user));
                setActiveAccount(accs[0]);
                let b = await web3.web3.eth.getBalance(accs[0]);
                setisConnected(true)
                setShow(false)
                setBalance(b);
                setId((parseInt(window.ethereum.chainId)).toString());
                // getContract(web3.web3,accs[0],b,(parseInt(window.ethereum.chainId)).toString())  
              }
              else{
                // alert('Invalid Signature');
                handleShowMsg()
                setMsgText("Invalid Signature")
              }
            } 
          }
        }
      }

     
    }
  }
  
  useEffect(()=>{
    if(process.env.REACT_APP_ADMIN == authedUser.address ){
    }else{
      // logingHandler();
    }
  },[])

  useEffect(() => {
    if(window.ethereum){
      window.ethereum.on("accountsChanged", logingHandler);
      return () => {
        // logingHandler();
        console.log("account change");
      };
    }
  });

  const logout = () => {
      dispatch(logoutUser());
      localStorage.clear();
      navigate.push('/')
  }

  return (
      <>
      <header className="container-fluid">
      <nav className="navbar navbar-expand-xl navbar-light custom-nav">
        <Link to="/" className="navbar-brand" href="#">
            <img className="img-fluid" src={logo} alt="" />
        </Link>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav m-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <NavLink className="nav-link" to="/">Markets</NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/userround">User History</NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/roundhistory">Round History</NavLink>
              </li>
           
              <li className="nav-item">
                <a className="nav-link" target="_blank" href="https://tenup.io/">Tenup</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" target="_blank" href="https://dapp.tenup.io/virtual-mining">Staking</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" target="_blank" href="https://copytrade.tenup.io/">Copy Trade</a>
              </li>
             
            </ul>
            {!authedUser?.address ?
            <div className="walletBtn btn" onClick={handleShow} >
             Connect Wallet
            </div>
            :
            <div className="walletBtn btn"  >
             {authedUser.address.slice(0,5)}...{authedUser.address.slice(38,authedUser.address.length)}
             <div className="logout" onClick={logout}>Logout</div>
            </div>
            }
        </div>
      </nav>
      </header>

      <Modal show={show} onHide={handleClose}>
       
        <Modal.Body>
          <div className="WalletModal">
            <h1>Connect your web3 wallet</h1>
            <div className="walletRow">

              {/* <div className="metamask">
                <div className="walletImg">
                  <img className="img-fluid" src={bitkeepLogo} alt="metamask" />
                </div>
                <div className="btnDiv">
                  <button className="" onClick={bitKeepHandler} ><span className="db-sm">Bitkeep</span> <span className="db-lg"> Connect Bitkeep</span></button>
                </div>
              </div> */}
              <div className="metamask">
                <div className="walletImg">
                  <img className="img-fluid" src={metamask} alt="metamask" />
                </div>
                <div className="btnDiv">
                  <button className="" onClick={logingHandler} ><span className="db-sm">Metamask</span> <span className="db-lg">Connect Metamask</span></button>
                </div>
              </div>
              <div className="walletConnect">
                <div className="walletImg">
                  <img className="img-fluid" src={walletConnect} alt="metamask" />
                </div>
                <div className="btnDiv">
                  <button className="" onClick={handleWalletConnect} >WalletConnect</button>
                </div>
              </div>
            </div>
            <div className="helpDv">
              <img src={help} alt="help" />
              By connecting your wallet, you agree to the Disclaimer, Terms of Use, Privacy Policy and risks attached.
            </div>
            <div className="closeIcon" onClick={handleClose}>
            <i class="fas fa-times"></i>
            </div>
          </div>
        </Modal.Body>
        
      </Modal>
      <AlertModal show={showMsg} handleClose={handleCloseMsg} msg={msgText} />
      </>
  )
};
